<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employeeTimeOff.add.headline">Add Employee Time Off</h1>
    <div>
        <a routerLink="/employeeTimeOffs" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@employeeTimeOff.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="startDate" datepicker="datepicker" i18n-label="@@employeeTimeOff.startDate.label" label="Start Date" />
    <app-input-row [group]="addForm" field="endDate" datepicker="datepicker" i18n-label="@@employeeTimeOff.endDate.label" label="End Date" />
    <app-input-row [group]="addForm" field="reason" rowType="textarea" i18n-label="@@employeeTimeOff.reason.label" label="Reason" />
    <app-input-row [group]="addForm" field="status" rowType="select" [options]="{'PENDING': 'PENDING', 'APPROVED': 'APPROVED', 'REJECTED': 'REJECTED', 'CANCELED': 'CANCELED'}" i18n-label="@@employeeTimeOff.status.label" label="Status" />
    <app-input-row [group]="addForm" field="employee" rowType="select" [options]="employeeValues" i18n-label="@@employeeTimeOff.employee.label" label="Employee" />
    <app-input-row [group]="addForm" field="approvedBy" rowType="select" [options]="approvedByValues" i18n-label="@@employeeTimeOff.approvedBy.label" label="Approved By" />
    <input type="submit" i18n-value="@@employeeTimeOff.add.headline" value="Add Employee Time Off" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
