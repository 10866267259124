import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { EmployeeScheduleService } from 'app/employee-schedule/employee-schedule.service';
import { EmployeeScheduleDTO } from 'app/employee-schedule/employee-schedule.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { updateForm } from 'app/common/utils';


@Component({
  selector: 'app-employee-schedule-edit',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './employee-schedule-edit.component.html'
})
export class EmployeeScheduleEditComponent implements OnInit {

  employeeScheduleService = inject(EmployeeScheduleService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  employeeValues?: Map<number,string>;
  shiftValues?: Map<number,string>;
  assignedByValues?: Map<number,string>;
  currentScheduleId?: number;

  editForm = new FormGroup({
    scheduleId: new FormControl({ value: null, disabled: true }),
    scheduleDate: new FormControl(null, [Validators.required]),
    availabilityStatus: new FormControl(null, [Validators.required]),
    employee: new FormControl(null, [Validators.required]),
    shift: new FormControl(null, [Validators.required]),
    assignedBy: new FormControl(null, [Validators.required])
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      updated: $localize`:@@employeeSchedule.update.success:Employee Schedule was updated successfully.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.currentScheduleId = +this.route.snapshot.params['scheduleId'];
    this.employeeScheduleService.getEmployeeValues()
        .subscribe({
          next: (data) => this.employeeValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.employeeScheduleService.getShiftValues()
        .subscribe({
          next: (data) => this.shiftValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.employeeScheduleService.getAssignedByValues()
        .subscribe({
          next: (data) => this.assignedByValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.employeeScheduleService.getEmployeeSchedule(this.currentScheduleId!)
        .subscribe({
          next: (data) => updateForm(this.editForm, data),
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const data = new EmployeeScheduleDTO(this.editForm.value);
    this.employeeScheduleService.updateEmployeeSchedule(this.currentScheduleId!, data)
        .subscribe({
          next: () => this.router.navigate(['/employeeSchedules'], {
            state: {
              msgSuccess: this.getMessage('updated')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.editForm, this.getMessage)
        });
  }

}
