import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MaintenanceScheduleDTO } from 'app/maintenance-schedule/maintenance-schedule.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';
import { map } from 'rxjs';
import { transformRecordToMap } from 'app/common/utils';


@Injectable({
  providedIn: 'root',
})
export class MaintenanceScheduleService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/maintenanceSchedules';

  getAllMaintenanceSchedules(params?: Record<string,string>) {
    return this.http.get<PagedModel<MaintenanceScheduleDTO>>(this.resourcePath, { params });
  }

  getMaintenanceSchedule(scheduleId: number) {
    return this.http.get<MaintenanceScheduleDTO>(this.resourcePath + '/' + scheduleId);
  }

  createMaintenanceSchedule(maintenanceScheduleDTO: MaintenanceScheduleDTO) {
    return this.http.post<number>(this.resourcePath, maintenanceScheduleDTO);
  }

  updateMaintenanceSchedule(scheduleId: number, maintenanceScheduleDTO: MaintenanceScheduleDTO) {
    return this.http.put<number>(this.resourcePath + '/' + scheduleId, maintenanceScheduleDTO);
  }

  deleteMaintenanceSchedule(scheduleId: number) {
    return this.http.delete(this.resourcePath + '/' + scheduleId);
  }

  getMachineValues() {
    return this.http.get<Record<string,number>>(this.resourcePath + '/machineValues')
        .pipe(map(transformRecordToMap));
  }

  getMaintenanceValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/maintenanceValues')
        .pipe(map(transformRecordToMap));
  }

  getAssignedEmployeeEmployeeValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/assignedEmployeeEmployeeValues')
        .pipe(map(transformRecordToMap));
  }

}
