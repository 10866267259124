export class TicketCommentsDTO {

  constructor(data:Partial<TicketCommentsDTO>) {
    Object.assign(this, data);
  }

  commentId?: number|null;
  commentText?: string|null;
  ticket?: number|null;
  commentedBy?: number|null;
  commenterName?: string|null;

}
