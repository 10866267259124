<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@ticketComments.edit.headline">Edit Ticket Comments</h1>
    <div>
        <a routerLink="/ticketCommentss" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@ticketComments.edit.back">Back to list</a>
    </div>
</div>
<form [formGroup]="editForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="editForm" field="commentId" rowType="number" i18n-label="@@ticketComments.commentId.label" label="Comment Id" />
    <app-input-row [group]="editForm" field="commentText" rowType="textarea" i18n-label="@@ticketComments.commentText.label" label="Comment Text" />
    <app-input-row [group]="editForm" field="ticket" rowType="select" [options]="ticketValues" i18n-label="@@ticketComments.ticket.label" label="Ticket" />
    <app-input-row [group]="editForm" field="commentedBy" rowType="select" [options]="commentedByValues" i18n-label="@@ticketComments.commentedBy.label" label="Commented By" />
    <input type="submit" i18n-value="@@ticketComments.edit.headline" value="Edit Ticket Comments" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
