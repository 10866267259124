<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employeeSchedule.add.headline">Add Employee Schedule</h1>
    <div>
        <a routerLink="/employeeSchedules" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@employeeSchedule.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="scheduleDate" datepicker="datepicker" i18n-label="@@employeeSchedule.scheduleDate.label" label="Schedule Date" />
    <app-input-row [group]="addForm" field="availabilityStatus" rowType="select" [options]="{'AVAILABLE': 'AVAILABLE', 'UNAVAILABLE': 'UNAVAILABLE', 'ON_BREAK': 'ON_BREAK', 'ON_LEAVE': 'ON_LEAVE', 'IN_TRANSIT': 'IN_TRANSIT'}" i18n-label="@@employeeSchedule.availabilityStatus.label" label="Availability Status" />
    <app-input-row [group]="addForm" field="employee" rowType="select" [options]="employeeValues" i18n-label="@@employeeSchedule.employee.label" label="Employee" />
    <app-input-row [group]="addForm" field="shift" rowType="select" [options]="shiftValues" i18n-label="@@employeeSchedule.shift.label" label="Shift" />
    <app-input-row [group]="addForm" field="assignedBy" rowType="select" [options]="assignedByValues" i18n-label="@@employeeSchedule.assignedBy.label" label="Assigned By" />
    <input type="submit" i18n-value="@@employeeSchedule.add.headline" value="Add Employee Schedule" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
