<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@machine.edit.headline">Edit Machine</h1>
    <div>
        <a routerLink="/machines" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@machine.edit.back">Back to list</a>
    </div>
</div>
<form [formGroup]="editForm" (ngSubmit)="handleSubmit()">
    <input type="submit" i18n-value="@@machine.edit.headline" value="Edit Machine" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6 mb-5" />
    <app-input-row [group]="editForm" field="machineId" rowType="number" i18n-label="@@machine.machineId.label" label="Machine Id" />
    <app-input-row [group]="editForm" field="serialNumber" i18n-label="@@machine.serialNumber.label" label="Serial Number" />
    <app-input-row [group]="editForm" field="machineName" i18n-label="@@machine.machineName.label" label="Machine Name" />
    <app-input-row [group]="editForm" field="machineQrcode" rowType="textarea" i18n-label="@@machine.machineQrcode.label" label="Machine Qrcode" />
    <app-input-row [group]="editForm" field="description" rowType="textarea" i18n-label="@@machine.description.label" label="Description" />
    <app-input-row [group]="editForm" field="installationDate" datepicker="datepicker" i18n-label="@@machine.installationDate.label" label="Installation Date" />
    <app-input-row [group]="editForm" field="lastServicedDate" datepicker="datepicker" i18n-label="@@machine.lastServicedDate.label" label="Last Serviced Date" />
    <app-input-row [group]="editForm" field="isOperational" rowType="checkbox" i18n-label="@@machine.isOperational.label" label="Is Operational" />
    <app-input-row [group]="editForm" field="status" rowType="select" [options]="{'DEPLOYED': 'DEPLOYED', 'READY_FOR_CUSTOMERS': 'READY_FOR_CUSTOMERS', 'IN_MAINTENANCE': 'IN_MAINTENANCE', 'OUT_OF_ORDER': 'OUT_OF_ORDER', 'DECOMMISSIONED': 'DECOMMISSIONED'}" i18n-label="@@machine.status.label" label="Status" />
    <app-input-row [group]="editForm" field="machineType" rowType="select" [options]="machineTypeValues" i18n-label="@@machine.machineType.label" label="Machine Type" />
    <app-input-row [group]="editForm" field="machineCategory" rowType="select" [options]="machineCategoryValues" i18n-label="@@machine.machineCategory.label" label="Machine Category" />
    <app-input-row [group]="editForm" field="location" rowType="select" [options]="locationValues" i18n-label="@@machine.location.label" label="Location" />
    <input type="submit" i18n-value="@@machine.edit.headline" value="Edit Machine" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
