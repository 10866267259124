<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@maintenance.edit.headline">Edit Maintenance</h1>
    <div>
        <a routerLink="/maintenances" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@maintenance.edit.back">Back to list</a>
    </div>
</div>
<form [formGroup]="editForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="editForm" field="maintenanceId" rowType="number" i18n-label="@@maintenance.maintenanceId.label" label="Maintenance Id" />
    <app-input-row [group]="editForm" field="maintenanceName" i18n-label="@@maintenance.maintenanceName.label" label="Maintenance Name" />
    <app-input-row [group]="editForm" field="maintenanceDescription" rowType="textarea" i18n-label="@@maintenance.maintenanceDescription.label" label="Maintenance Description" />
    <app-input-row [group]="editForm" field="machineType" rowType="select" [options]="machineTypeValues" i18n-label="@@maintenance.machineType.label" label="Machine Type" />
    <app-input-row [group]="editForm" field="maintenanceType" rowType="select" [options]="maintenanceTypeValues" i18n-label="@@maintenance.maintenanceType.label" label="Maintenance Type" />
    <input type="submit" i18n-value="@@maintenance.edit.headline" value="Edit Maintenance" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
