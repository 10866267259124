import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ServiceTicketDTO } from 'app/service-ticket/service-ticket.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';
import { map } from 'rxjs';
import { transformRecordToMap } from 'app/common/utils';


@Injectable({
  providedIn: 'root',
})
export class ServiceTicketService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/serviceTickets';

  getAllServiceTickets(params?: Record<string,string>) {
    return this.http.get<PagedModel<ServiceTicketDTO>>(this.resourcePath, { params });
  }

  getServiceTicket(ticketId: number) {
    return this.http.get<ServiceTicketDTO>(this.resourcePath + '/' + ticketId);
  }

  createServiceTicket(serviceTicketDTO: ServiceTicketDTO) {
    return this.http.post<number>(this.resourcePath, serviceTicketDTO);
  }

  updateServiceTicket(ticketId: number, serviceTicketDTO: ServiceTicketDTO) {
    return this.http.put<number>(this.resourcePath + '/' + ticketId, serviceTicketDTO);
  }

  deleteServiceTicket(ticketId: number) {
    return this.http.delete(this.resourcePath + '/' + ticketId);
  }

  getMachineValues() {
    return this.http.get<Record<string,number>>(this.resourcePath + '/machineValues')
        .pipe(map(transformRecordToMap));
  }

  getReportedByValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/reportedByValues')
        .pipe(map(transformRecordToMap));
  }

  getAssignedToValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/assignedToValues')
        .pipe(map(transformRecordToMap));
  }

  getMaintenanceValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/maintenanceValues')
        .pipe(map(transformRecordToMap));
  }

}
