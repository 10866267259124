import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { MachineLocationService } from 'app/machine-location/machine-location.service';
import { MachineLocationDTO } from 'app/machine-location/machine-location.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { updateForm } from 'app/common/utils';


@Component({
  selector: 'app-machine-location-edit',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './machine-location-edit.component.html'
})
export class MachineLocationEditComponent implements OnInit {

  machineLocationService = inject(MachineLocationService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  currentLocationId?: number;

  editForm = new FormGroup({
    locationId: new FormControl({ value: null, disabled: true }),
    locationName: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    locationType: new FormControl(null),
    locationDescription: new FormControl(null),
    officialName: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    phone: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
    email: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    isActive: new FormControl(false),
    openingHours: new FormControl(null, [Validators.maxLength(255)]),
    addressLine1: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    addressLine2: new FormControl(null, [Validators.maxLength(255)]),
    landmark: new FormControl(null, [Validators.maxLength(255)]),
    notes: new FormControl(null),
    postCode: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
    city: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    state: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    country: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    latitude: new FormControl(null, [Validators.maxLength(100)]),
    longitude: new FormControl(null, [Validators.maxLength(100)]),
    plusCode: new FormControl(null, [Validators.maxLength(100)])
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      updated: $localize`:@@machineLocation.update.success:Machine Location was updated successfully.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.currentLocationId = +this.route.snapshot.params['locationId'];
    this.machineLocationService.getMachineLocation(this.currentLocationId!)
        .subscribe({
          next: (data) => updateForm(this.editForm, data),
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const data = new MachineLocationDTO(this.editForm.value);
    this.machineLocationService.updateMachineLocation(this.currentLocationId!, data)
        .subscribe({
          next: () => this.router.navigate(['/machineLocations'], {
            state: {
              msgSuccess: this.getMessage('updated')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.editForm, this.getMessage)
        });
  }

}
