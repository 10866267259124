<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@maintenanceTask.add.headline">Add Maintenance Task</h1>
    <div>
        <a routerLink="/maintenanceTasks" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@maintenanceTask.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="taskName" i18n-label="@@maintenanceTask.taskName.label" label="Task Name" />
    <app-input-row [group]="addForm" field="taskDescription" rowType="textarea" i18n-label="@@maintenanceTask.taskDescription.label" label="Task Description" />
    <app-input-row [group]="addForm" field="taskOrder" rowType="number" i18n-label="@@maintenanceTask.taskOrder.label" label="Task Order" />
    <app-input-row [group]="addForm" field="maintenance" rowType="select" [options]="maintenanceValues" i18n-label="@@maintenanceTask.maintenance.label" label="Maintenance" />
    <input type="submit" i18n-value="@@maintenanceTask.add.headline" value="Add Maintenance Task" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
