import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { MaintenanceTaskService } from 'app/maintenance-task/maintenance-task.service';
import { MaintenanceTaskDTO } from 'app/maintenance-task/maintenance-task.model';
import { SearchFilterComponent } from 'app/common/list-helper/search-filter.component';
import { SortingComponent } from 'app/common/list-helper/sorting.component';
import { getListParams } from 'app/common/utils';
import { PagedModel, PaginationComponent } from 'app/common/list-helper/pagination.component';


@Component({
  selector: 'app-maintenance-task-list',
  standalone: true,
  imports: [CommonModule, SearchFilterComponent ,SortingComponent, PaginationComponent, RouterLink],
  templateUrl: './maintenance-task-list.component.html'})
export class MaintenanceTaskListComponent implements OnInit, OnDestroy {

  maintenanceTaskService = inject(MaintenanceTaskService);
  errorHandler = inject(ErrorHandler);
  route = inject(ActivatedRoute);
  router = inject(Router);
  maintenanceTasks?: PagedModel<MaintenanceTaskDTO>;
  navigationSubscription?: Subscription;

  sortOptions = {
    'taskId,ASC': $localize`:@@maintenanceTask.list.sort.taskId,ASC:Sort by Task Id (Ascending)`, 
    'taskName,ASC': $localize`:@@maintenanceTask.list.sort.taskName,ASC:Sort by Task Name (Ascending)`, 
    'taskDescription,ASC': $localize`:@@maintenanceTask.list.sort.taskDescription,ASC:Sort by Task Description (Ascending)`
  }

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      confirm: $localize`:@@delete.confirm:Do you really want to delete this element? This cannot be undone.`,
      deleted: $localize`:@@maintenanceTask.delete.success:Maintenance Task was removed successfully.`    };
    return messages[key];
  }

  ngOnInit() {
    this.loadData();
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadData();
      }
    });
  }

  ngOnDestroy() {
    this.navigationSubscription!.unsubscribe();
  }
  
  loadData() {
    this.maintenanceTaskService.getAllMaintenanceTasks(getListParams(this.route))
        .subscribe({
          next: (data) => this.maintenanceTasks = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  confirmDelete(taskId: number) {
    if (confirm(this.getMessage('confirm'))) {
      this.maintenanceTaskService.deleteMaintenanceTask(taskId)
          .subscribe({
            next: () => this.router.navigate(['/maintenanceTasks'], {
              state: {
                msgInfo: this.getMessage('deleted')
              }
            }),
            error: (error) => this.errorHandler.handleServerError(error.error)
          });
    }
  }

}
