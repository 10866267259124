export class MaintenanceDTO {

  constructor(data:Partial<MaintenanceDTO>) {
    Object.assign(this, data);
  }

  maintenanceId?: number|null;
  maintenanceName?: string|null;
  maintenanceDescription?: string|null;
  machineType?: number|null;
  machineTypeName?: string|null;
  maintenanceType?: number|null;
  maintenanceTypeName?: string|null;

}
