import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { MachineLocationService } from 'app/machine-location/machine-location.service';
import { MachineLocationDTO } from 'app/machine-location/machine-location.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';


@Component({
  selector: 'app-machine-location-add',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './machine-location-add.component.html'
})
export class MachineLocationAddComponent {

  machineLocationService = inject(MachineLocationService);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  addForm = new FormGroup({
    locationName: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    locationType: new FormControl(null),
    locationDescription: new FormControl(null),
    officialName: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    phone: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
    email: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    isActive: new FormControl(false),
    openingHours: new FormControl(null, [Validators.maxLength(255)]),
    addressLine1: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    addressLine2: new FormControl(null, [Validators.maxLength(255)]),
    landmark: new FormControl(null, [Validators.maxLength(255)]),
    notes: new FormControl(null),
    postCode: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
    city: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    state: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    country: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    latitude: new FormControl(null, [Validators.maxLength(100)]),
    longitude: new FormControl(null, [Validators.maxLength(100)]),
    plusCode: new FormControl(null, [Validators.maxLength(100)])
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      created: $localize`:@@machineLocation.create.success:Machine Location was created successfully.`
    };
    return messages[key];
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.addForm.markAllAsTouched();
    if (!this.addForm.valid) {
      return;
    }
    const data = new MachineLocationDTO(this.addForm.value);
    this.machineLocationService.createMachineLocation(data)
        .subscribe({
          next: () => this.router.navigate(['/machineLocations'], {
            state: {
              msgSuccess: this.getMessage('created')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.addForm, this.getMessage)
        });
  }

}
