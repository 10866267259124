export class MachineTypeDTO {

  constructor(data:Partial<MachineTypeDTO>) {
    Object.assign(this, data);
  }

  typeId?: number|null;
  typeName?: string|null;
  typeDescription?: string|null;

}
