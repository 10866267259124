export class EmployeeRecurringScheduleDTO {

  constructor(data:Partial<EmployeeRecurringScheduleDTO>) {
    Object.assign(this, data);
  }

  id?: number|null;
  startDate?: string|null;
  endDate?: string|null;
  employee?: number|null;
  employeeName?: string|null;
  shift?: number|null;
  shiftName?:string|null;

}
