<h1 class="grow text-3xl md:text-4xl font-medium mb-8" i18n="@@home.index.headline">Welcome to JuiceRobo</h1>
<p class="mb-12">
    <span i18n="@@home.index.swagger">Swagger is</span>
    <a [href]="environment.apiPath + '/swagger-ui.html'" target="_blank" class="underline" i18n="@@home.index.swagger.link">available here</a>.
</p>
<div class="md:w-2/5 mb-12">
    <h4 class="text-2xl font-medium mb-4" i18n="@@home.index.exploreEntities">Explore your entities</h4>
    <div class="flex flex-col border border-gray-300 rounded">
        <a routerLink="/departments" class="w-full border-gray-300 hover:bg-gray-100 border-b rounded-t px-4 py-2" i18n="@@department.list.headline">Departments</a>
        <a routerLink="/roles" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@role.list.headline">Roles</a>
        <a routerLink="/employees" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@employee.list.headline">Employees</a>
        <a routerLink="/employeeProfiles" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@employeeProfile.list.headline">Employee Profiles</a>
        <a routerLink="/machineLocations" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@machineLocation.list.headline">Machine Locations</a>
        <a routerLink="/machineTypes" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@machineType.list.headline">Machine Types</a>
        <a routerLink="/machineCategories" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@machineCategory.list.headline">Machine Categories</a>
        <a routerLink="/maintenanceTypes" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@maintenanceType.list.headline">Maintenance Types</a>
        <a routerLink="/maintenances" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@maintenance.list.headline">Maintenances</a>
        <a routerLink="/maintenanceTasks" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@maintenanceTask.list.headline">Maintenance Tasks</a>
        <a routerLink="/machines" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@machine.list.headline">Machines</a>
        <a routerLink="/employeeTimeOffs" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@employeeTimeOff.list.headline">Employee Time Offs</a>
        <a routerLink="/employeeShifts" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@employeeShift.list.headline">Employee Shifts</a>
        <a routerLink="/employeeSchedules" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@employeeSchedule.list.headline">Employee Schedules</a>
        <a routerLink="/employeeRecurringSchedules" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@employeeRecurringSchedule.list.headline">Employee Recurring Schedules</a>
        <a routerLink="/maintenanceSchedules" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@maintenanceSchedule.list.headline">Maintenance Schedules</a>
        <a routerLink="/maintenanceStatusLogs" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@maintenanceStatusLog.list.headline">Maintenance Status Logs</a>
        <a routerLink="/maintenanceTaskLogs" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@maintenanceTaskLog.list.headline">Maintenance Task Logs</a>
        <a routerLink="/serviceTickets" class="w-full border-gray-300 hover:bg-gray-100 border-b px-4 py-2" i18n="@@serviceTicket.list.headline">Service Tickets</a>
        <a routerLink="/ticketCommentss" class="w-full border-gray-300 hover:bg-gray-100 rounded-b px-4 py-2" i18n="@@ticketComments.list.headline">Ticket Commentses</a>
    </div>
</div>
