<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@role.add.headline">Add Role</h1>
    <div>
        <a routerLink="/roles" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@role.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="roleName" i18n-label="@@role.roleName.label" label="Role Name" />
    <app-input-row [group]="addForm" field="roleDescription" rowType="textarea" i18n-label="@@role.roleDescription.label" label="Role Description" />
    <input type="submit" i18n-value="@@role.add.headline" value="Add Role" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
