import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { EmployeeScheduleDTO } from 'app/employee-schedule/employee-schedule.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';
import { map } from 'rxjs';
import { transformRecordToMap } from 'app/common/utils';


@Injectable({
  providedIn: 'root',
})
export class EmployeeScheduleService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/employeeSchedules';

  getAllEmployeeSchedules(params?: Record<string,string>) {
    return this.http.get<PagedModel<EmployeeScheduleDTO>>(this.resourcePath, { params });
  }

  getEmployeeSchedule(scheduleId: number) {
    return this.http.get<EmployeeScheduleDTO>(this.resourcePath + '/' + scheduleId);
  }

  createEmployeeSchedule(employeeScheduleDTO: EmployeeScheduleDTO) {
    return this.http.post<number>(this.resourcePath, employeeScheduleDTO);
  }

  updateEmployeeSchedule(scheduleId: number, employeeScheduleDTO: EmployeeScheduleDTO) {
    return this.http.put<number>(this.resourcePath + '/' + scheduleId, employeeScheduleDTO);
  }

  deleteEmployeeSchedule(scheduleId: number) {
    return this.http.delete(this.resourcePath + '/' + scheduleId);
  }

  getEmployeeValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/employeeValues')
        .pipe(map(transformRecordToMap));
  }

  getShiftValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/shiftValues')
        .pipe(map(transformRecordToMap));
  }

  getAssignedByValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/assignedByValues')
        .pipe(map(transformRecordToMap));
  }

}
