export class MaintenanceStatusLogDTO {

  constructor(data:Partial<MaintenanceStatusLogDTO>) {
    Object.assign(this, data);
  }

  id?: number|null;
  status?: string|null;
  maintenance?: number|null;
  maintenanceName?: string|null;
  assignedEmployeeEmail?: number|null;
  assigneeName?: string|null;
  machine?: number|null;
  machineName?: string|null;

}
