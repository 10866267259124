<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@machine.list.headline">Machines</h1>
    <div>
        <a routerLink="/machines/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@machine.list.createNew">Create new Machine</a>
    </div>
</div>
@if ((machines && machines.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@machine.list.filter" placeholder="Filter by Machine Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!machines || machines.page.totalElements === 0) {
<div i18n="@@machine.list.empty">No Machines could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@machine.machineId.label">Machine Id</th>
                <th scope="col" class="text-left p-2" i18n="@@machine.serialNumber.label">Serial Number</th>
                <th scope="col" class="text-left p-2" i18n="@@machine.machineName.label">Machine Name</th>
                <th scope="col" class="text-left p-2" i18n="@@machine.installationDate.label">Installation Date</th>
                <th scope="col" class="text-left p-2" i18n="@@machine.lastServicedDate.label">Last Serviced Date</th>
                <th scope="col" class="text-left p-2" i18n="@@machine.isOperational.label">Is Operational</th>
                <th scope="col" class="text-left p-2" i18n="@@machine.status.label">Status</th>
                <th scope="col" class="text-left p-2" i18n="@@machine.machineType.label">Machine Type</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (machine of machines.content; track machine.machineId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ machine.machineId }}</td>
                <td class="p-2">{{ machine.serialNumber }}</td>
                <td class="p-2">{{ machine.machineName }}</td>
                <td class="p-2">{{ machine.installationDate }}</td>
                <td class="p-2">{{ machine.lastServicedDate }}</td>
                <td class="p-2">{{ machine.isOperational }}</td>
                <td class="p-2">{{ machine.status }}</td>
                <td class="p-2">{{ machine.machineTypeName }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/machines/edit', machine.machineId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@machine.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(machine.machineId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@machine.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="machines.page" />
}
