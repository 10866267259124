export class EmployeeTimeOffDTO {

  constructor(data:Partial<EmployeeTimeOffDTO>) {
    Object.assign(this, data);
  }

  id?: number|null;
  startDate?: string|null;
  endDate?: string|null;
  reason?: string|null;
  status?: string|null;
  employee?: number|null;
  employeeName?: string|null;
  approvedBy?: number|null;
  approverName?: string|null;

}
