export class MaintenanceTaskDTO {

  constructor(data:Partial<MaintenanceTaskDTO>) {
    Object.assign(this, data);
  }

  taskId?: number|null;
  taskName?: string|null;
  taskDescription?: string|null;
  taskOrder?: number|null;
  maintenance?: number|null;
  maintenanceName?: string|null;

}
