import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MachineTypeDTO } from 'app/machine-type/machine-type.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';


@Injectable({
  providedIn: 'root',
})
export class MachineTypeService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/machineTypes';

  getAllMachineTypes(params?: Record<string,string>) {
    return this.http.get<PagedModel<MachineTypeDTO>>(this.resourcePath, { params });
  }

  getMachineType(typeId: number) {
    return this.http.get<MachineTypeDTO>(this.resourcePath + '/' + typeId);
  }

  createMachineType(machineTypeDTO: MachineTypeDTO) {
    return this.http.post<number>(this.resourcePath, machineTypeDTO);
  }

  updateMachineType(typeId: number, machineTypeDTO: MachineTypeDTO) {
    return this.http.put<number>(this.resourcePath + '/' + typeId, machineTypeDTO);
  }

  deleteMachineType(typeId: number) {
    return this.http.delete(this.resourcePath + '/' + typeId);
  }

}
