<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employeeRecurringSchedule.edit.headline">Edit Employee Recurring Schedule</h1>
    <div>
        <a routerLink="/employeeRecurringSchedules" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@employeeRecurringSchedule.edit.back">Back to list</a>
    </div>
</div>
<form [formGroup]="editForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="editForm" field="id" rowType="number" i18n-label="@@employeeRecurringSchedule.id.label" label="Id" />
    <app-input-row [group]="editForm" field="startDate" datepicker="datepicker" i18n-label="@@employeeRecurringSchedule.startDate.label" label="Start Date" />
    <app-input-row [group]="editForm" field="endDate" datepicker="datepicker" i18n-label="@@employeeRecurringSchedule.endDate.label" label="End Date" />
    <app-input-row [group]="editForm" field="employee" rowType="select" [options]="employeeValues" i18n-label="@@employeeRecurringSchedule.employee.label" label="Employee" />
    <app-input-row [group]="editForm" field="shift" rowType="select" [options]="shiftValues" i18n-label="@@employeeRecurringSchedule.shift.label" label="Shift" />
    <input type="submit" i18n-value="@@employeeRecurringSchedule.edit.headline" value="Edit Employee Recurring Schedule" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
