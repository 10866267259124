<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employeeProfile.list.headline">Employee Profiles</h1>
    <div>
        <a routerLink="/employeeProfiles/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@employeeProfile.list.createNew">Create new Employee Profile</a>
    </div>
</div>
@if ((employeeProfiles && employeeProfiles.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@employeeProfile.list.filter" placeholder="Filter by Profile Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!employeeProfiles || employeeProfiles.page.totalElements === 0) {
<div i18n="@@employeeProfile.list.empty">No Employee Profiles could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@employeeProfile.profileId.label">Profile Id</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeProfile.jobTitle.label">Job Title</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeProfile.hireDate.label">Hire Date</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeProfile.gender.label">Gender</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeProfile.birthdate.label">Birthdate</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeProfile.driverLicense.label">Driver License</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeProfile.vehicleDetails.label">Vehicle Details</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeProfile.addressLine1.label">Address Line1</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (employeeProfile of employeeProfiles.content; track employeeProfile.profileId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ employeeProfile.profileId }}</td>
                <td class="p-2">{{ employeeProfile.jobTitle }}</td>
                <td class="p-2">{{ employeeProfile.hireDate }}</td>
                <td class="p-2">{{ employeeProfile.gender }}</td>
                <td class="p-2">{{ employeeProfile.birthdate }}</td>
                <td class="p-2">{{ employeeProfile.driverLicense }}</td>
                <td class="p-2">{{ employeeProfile.vehicleDetails }}</td>
                <td class="p-2">{{ employeeProfile.addressLine1 }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/employeeProfiles/edit', employeeProfile.profileId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@employeeProfile.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(employeeProfile.profileId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@employeeProfile.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="employeeProfiles.page" />
}
