<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@maintenance.list.headline">Maintenances</h1>
    <div>
        <a routerLink="/maintenances/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@maintenance.list.createNew">Create new Maintenance</a>
    </div>
</div>
@if ((maintenances && maintenances.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@maintenance.list.filter" placeholder="Filter by Maintenance Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!maintenances || maintenances.page.totalElements === 0) {
<div i18n="@@maintenance.list.empty">No Maintenances could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@maintenance.maintenanceId.label">Maintenance Id</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenance.maintenanceName.label">Maintenance Name</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenance.machineType.label">Machine Type</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenance.maintenanceType.label">Maintenance Type</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (maintenance of maintenances.content; track maintenance.maintenanceId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ maintenance.maintenanceId }}</td>
                <td class="p-2">{{ maintenance.maintenanceName }}</td>
                <td class="p-2">{{ maintenance.machineTypeName }}</td>
                <td class="p-2">{{ maintenance.maintenanceTypeName }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/maintenances/edit', maintenance.maintenanceId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@maintenance.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(maintenance.maintenanceId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@maintenance.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="maintenances.page" />
}
