export class MaintenanceTaskLogDTO {

  constructor(data:Partial<MaintenanceTaskLogDTO>) {
    Object.assign(this, data);
  }

  taskId?: number|null;
  taskName?: string|null;
  description?: string|null;
  taskOrder?: number|null;
  taskStatus?: string|null;
  taskPhotoProofUrl?: string|null;
  maintenance?: number|null;
  maintenanceName?: string|null;
  assignedEmployeeEmail?: number|null;
  assigneeName?: string|null;
  machine?: number|null;
  machineName?: string|null;

}
