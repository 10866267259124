import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { MachineLocationService } from 'app/machine-location/machine-location.service';
import { MachineLocationDTO } from 'app/machine-location/machine-location.model';
import { SearchFilterComponent } from 'app/common/list-helper/search-filter.component';
import { SortingComponent } from 'app/common/list-helper/sorting.component';
import { getListParams } from 'app/common/utils';
import { PagedModel, PaginationComponent } from 'app/common/list-helper/pagination.component';


@Component({
  selector: 'app-machine-location-list',
  standalone: true,
  imports: [CommonModule, SearchFilterComponent ,SortingComponent, PaginationComponent, RouterLink],
  templateUrl: './machine-location-list.component.html'})
export class MachineLocationListComponent implements OnInit, OnDestroy {

  machineLocationService = inject(MachineLocationService);
  errorHandler = inject(ErrorHandler);
  route = inject(ActivatedRoute);
  router = inject(Router);
  machineLocations?: PagedModel<MachineLocationDTO>;
  navigationSubscription?: Subscription;

  sortOptions = {
    'locationId,ASC': $localize`:@@machineLocation.list.sort.locationId,ASC:Sort by Location Id (Ascending)`, 
    'locationName,ASC': $localize`:@@machineLocation.list.sort.locationName,ASC:Sort by Location Name (Ascending)`, 
    'locationType,ASC': $localize`:@@machineLocation.list.sort.locationType,ASC:Sort by Location Type (Ascending)`
  }

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      confirm: $localize`:@@delete.confirm:Do you really want to delete this element? This cannot be undone.`,
      deleted: $localize`:@@machineLocation.delete.success:Machine Location was removed successfully.`,
      'machineLocation.machine.location.referenced': $localize`:@@machineLocation.machine.location.referenced:This entity is still referenced by Machine ${details?.id} via field Location.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.loadData();
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadData();
      }
    });
  }

  ngOnDestroy() {
    this.navigationSubscription!.unsubscribe();
  }
  
  loadData() {
    this.machineLocationService.getAllMachineLocations(getListParams(this.route))
        .subscribe({
          next: (data) => this.machineLocations = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  confirmDelete(locationId: number) {
    if (confirm(this.getMessage('confirm'))) {
      this.machineLocationService.deleteMachineLocation(locationId)
          .subscribe({
            next: () => this.router.navigate(['/machineLocations'], {
              state: {
                msgInfo: this.getMessage('deleted')
              }
            }),
            error: (error) => {
              if (error.error?.code === 'REFERENCED') {
                const messageParts = error.error.message.split(',');
                this.router.navigate(['/machineLocations'], {
                  state: {
                    msgError: this.getMessage(messageParts[0], { id: messageParts[1] })
                  }
                });
                return;
              }
              this.errorHandler.handleServerError(error.error)
            }
          });
    }
  }

}
