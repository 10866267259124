<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@ticketComments.list.headline">Ticket Commentses</h1>
    <div>
        <a routerLink="/ticketCommentss/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@ticketComments.list.createNew">Create new Ticket Comments</a>
    </div>
</div>
@if ((ticketCommentses && ticketCommentses.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@ticketComments.list.filter" placeholder="Filter by Comment Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!ticketCommentses || ticketCommentses.page.totalElements === 0) {
<div i18n="@@ticketComments.list.empty">No Ticket Commentses could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@ticketComments.commentId.label">Comment Id</th>
                <th scope="col" class="text-left p-2" i18n="@@ticketComments.ticket.label">Ticket</th>
                <th scope="col" class="text-left p-2" i18n="@@ticketComments.commentedBy.label">Commented By</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (ticketComments of ticketCommentses.content; track ticketComments.commentId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ ticketComments.commentId }}</td>
                <td class="p-2">{{ ticketComments.ticket }}</td>
                <td class="p-2">{{ ticketComments.commenterName }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/ticketCommentss/edit', ticketComments.commentId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@ticketComments.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(ticketComments.commentId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@ticketComments.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="ticketCommentses.page" />
}
