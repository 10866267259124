import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { MachineCategoryService } from 'app/machine-category/machine-category.service';
import { MachineCategoryDTO } from 'app/machine-category/machine-category.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { updateForm } from 'app/common/utils';


@Component({
  selector: 'app-machine-category-edit',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './machine-category-edit.component.html'
})
export class MachineCategoryEditComponent implements OnInit {

  machineCategoryService = inject(MachineCategoryService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  currentCategoryId?: number;

  editForm = new FormGroup({
    categoryId: new FormControl({ value: null, disabled: true }),
    categoryName: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    categoryDescription: new FormControl(null)
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      updated: $localize`:@@machineCategory.update.success:Machine Category was updated successfully.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.currentCategoryId = +this.route.snapshot.params['categoryId'];
    this.machineCategoryService.getMachineCategory(this.currentCategoryId!)
        .subscribe({
          next: (data) => updateForm(this.editForm, data),
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const data = new MachineCategoryDTO(this.editForm.value);
    this.machineCategoryService.updateMachineCategory(this.currentCategoryId!, data)
        .subscribe({
          next: () => this.router.navigate(['/machineCategories'], {
            state: {
              msgSuccess: this.getMessage('updated')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.editForm, this.getMessage)
        });
  }

}
