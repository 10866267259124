import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { EmployeeService } from 'app/employee/employee.service';
import { EmployeeDTO } from 'app/employee/employee.model';
import { SearchFilterComponent } from 'app/common/list-helper/search-filter.component';
import { SortingComponent } from 'app/common/list-helper/sorting.component';
import { getListParams } from 'app/common/utils';
import { PagedModel, PaginationComponent } from 'app/common/list-helper/pagination.component';


@Component({
  selector: 'app-employee-list',
  standalone: true,
  imports: [CommonModule, SearchFilterComponent ,SortingComponent, PaginationComponent, RouterLink],
  templateUrl: './employee-list.component.html'})
export class EmployeeListComponent implements OnInit, OnDestroy {

  employeeService = inject(EmployeeService);
  errorHandler = inject(ErrorHandler);
  route = inject(ActivatedRoute);
  router = inject(Router);
  employees?: PagedModel<EmployeeDTO>;
  navigationSubscription?: Subscription;

  sortOptions = {
    'employeeId,ASC': $localize`:@@employee.list.sort.employeeId,ASC:Sort by Employee Id (Ascending)`, 
    'firstName,ASC': $localize`:@@employee.list.sort.firstName,ASC:Sort by First Name (Ascending)`, 
    'lastName,ASC': $localize`:@@employee.list.sort.lastName,ASC:Sort by Last Name (Ascending)`
  }

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      confirm: $localize`:@@delete.confirm:Do you really want to delete this element? This cannot be undone.`,
      deleted: $localize`:@@employee.delete.success:Employee was removed successfully.`,
      'employee.employeeProfile.employee.referenced': $localize`:@@employee.employeeProfile.employee.referenced:This entity is still referenced by Employee Profile ${details?.id} via field Employee.`,
      'employee.employeeTimeOff.employee.referenced': $localize`:@@employee.employeeTimeOff.employee.referenced:This entity is still referenced by Employee Time Off ${details?.id} via field Employee.`,
      'employee.employeeTimeOff.approvedBy.referenced': $localize`:@@employee.employeeTimeOff.approvedBy.referenced:This entity is still referenced by Employee Time Off ${details?.id} via field Approved By.`,
      'employee.employeeSchedule.employee.referenced': $localize`:@@employee.employeeSchedule.employee.referenced:This entity is still referenced by Employee Schedule ${details?.id} via field Employee.`,
      'employee.employeeSchedule.assignedBy.referenced': $localize`:@@employee.employeeSchedule.assignedBy.referenced:This entity is still referenced by Employee Schedule ${details?.id} via field Assigned By.`,
      'employee.employeeRecurringSchedule.employee.referenced': $localize`:@@employee.employeeRecurringSchedule.employee.referenced:This entity is still referenced by Employee Recurring Schedule ${details?.id} via field Employee.`,
      'employee.maintenanceSchedule.assignedEmployeeEmployee.referenced': $localize`:@@employee.maintenanceSchedule.assignedEmployeeEmployee.referenced:This entity is still referenced by Maintenance Schedule ${details?.id} via field Assigned Employee Employee.`,
      'employee.maintenanceStatusLog.assignedEmployeeEmail.referenced': $localize`:@@employee.maintenanceStatusLog.assignedEmployeeEmail.referenced:This entity is still referenced by Maintenance Status Log ${details?.id} via field Assigned Employee Email.`,
      'employee.maintenanceTaskLog.assignedEmployeeEmail.referenced': $localize`:@@employee.maintenanceTaskLog.assignedEmployeeEmail.referenced:This entity is still referenced by Maintenance Task Log ${details?.id} via field Assigned Employee Email.`,
      'employee.serviceTicket.reportedBy.referenced': $localize`:@@employee.serviceTicket.reportedBy.referenced:This entity is still referenced by Service Ticket ${details?.id} via field Reported By.`,
      'employee.serviceTicket.assignedTo.referenced': $localize`:@@employee.serviceTicket.assignedTo.referenced:This entity is still referenced by Service Ticket ${details?.id} via field Assigned To.`,
      'employee.ticketComments.commentedBy.referenced': $localize`:@@employee.ticketComments.commentedBy.referenced:This entity is still referenced by Ticket Comments ${details?.id} via field Commented By.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.loadData();
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadData();
      }
    });
  }

  ngOnDestroy() {
    this.navigationSubscription!.unsubscribe();
  }
  
  loadData() {
    this.employeeService.getAllEmployees(getListParams(this.route))
        .subscribe({
          next: (data) => this.employees = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  confirmDelete(employeeId: number) {
    if (confirm(this.getMessage('confirm'))) {
      this.employeeService.deleteEmployee(employeeId)
          .subscribe({
            next: () => this.router.navigate(['/employees'], {
              state: {
                msgInfo: this.getMessage('deleted')
              }
            }),
            error: (error) => {
              if (error.error?.code === 'REFERENCED') {
                const messageParts = error.error.message.split(',');
                this.router.navigate(['/employees'], {
                  state: {
                    msgError: this.getMessage(messageParts[0], { id: messageParts[1] })
                  }
                });
                return;
              }
              this.errorHandler.handleServerError(error.error)
            }
          });
    }
  }

}
