export class EmployeeScheduleDTO {

  constructor(data:Partial<EmployeeScheduleDTO>) {
    Object.assign(this, data);
  }

  scheduleId?: number|null;
  scheduleDate?: string|null;
  availabilityStatus?: string|null;
  employee?: number|null;
  employeeName?: string|null;
  shift?: number|null;
  shiftName?: string|null;
  assignedBy?: number|null;
  assignerName?: string|null;

}
