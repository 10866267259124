<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employee.list.headline">Employees</h1>
    <div>
        <a routerLink="/employees/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@employee.list.createNew">Create new Employee</a>
    </div>
</div>
@if ((employees && employees.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@employee.list.filter" placeholder="Filter by Employee Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!employees || employees.page.totalElements === 0) {
<div i18n="@@employee.list.empty">No Employees could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@employee.employeeId.label">Employee Id</th>
                <th scope="col" class="text-left p-2" i18n="@@employee.firstName.label">First Name</th>
                <th scope="col" class="text-left p-2" i18n="@@employee.lastName.label">Last Name</th>
                <th scope="col" class="text-left p-2" i18n="@@employee.email.label">Email</th>
                <th scope="col" class="text-left p-2" i18n="@@employee.phoneNumber.label">Phone Number</th>
                <th scope="col" class="text-left p-2" i18n="@@employee.isActive.label">Is Active</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (employee of employees.content; track employee.employeeId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ employee.employeeId }}</td>
                <td class="p-2">{{ employee.firstName }}</td>
                <td class="p-2">{{ employee.lastName }}</td>
                <td class="p-2">{{ employee.email }}</td>
                <td class="p-2">{{ employee.phoneNumber }}</td>
                <td class="p-2">{{ employee.isActive }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/employees/edit', employee.employeeId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@employee.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(employee.employeeId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@employee.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="employees.page" />
}
