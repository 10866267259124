import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { ServiceTicketService } from 'app/service-ticket/service-ticket.service';
import { ServiceTicketDTO } from 'app/service-ticket/service-ticket.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { updateForm, validOffsetDateTime } from 'app/common/utils';


@Component({
  selector: 'app-service-ticket-edit',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './service-ticket-edit.component.html'
})
export class ServiceTicketEditComponent implements OnInit {

  serviceTicketService = inject(ServiceTicketService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  machineValues?: Map<number,string>;
  reportedByValues?: Map<number,string>;
  assignedToValues?: Map<number,string>;
  maintenanceValues?: Map<number,string>;
  currentTicketId?: number;

  editForm = new FormGroup({
    ticketId: new FormControl({ value: null, disabled: true }),
    issueDescription: new FormControl(null, [Validators.required]),
    ticketStatus: new FormControl(null, [Validators.required]),
    priority: new FormControl(null, [Validators.required]),
    taskPhotoProofUrl: new FormControl(null),
    assignedDate: new FormControl(null, [validOffsetDateTime]),
    resolvedDate: new FormControl(null, [validOffsetDateTime]),
    machine: new FormControl(null, [Validators.required]),
    reportedBy: new FormControl(null, [Validators.required]),
    assignedTo: new FormControl(null, [Validators.required]),
    maintenance: new FormControl(null, [Validators.required])
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      updated: $localize`:@@serviceTicket.update.success:Service Ticket was updated successfully.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.currentTicketId = +this.route.snapshot.params['ticketId'];
    this.serviceTicketService.getMachineValues()
        .subscribe({
          next: (data) => this.machineValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.serviceTicketService.getReportedByValues()
        .subscribe({
          next: (data) => this.reportedByValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.serviceTicketService.getAssignedToValues()
        .subscribe({
          next: (data) => this.assignedToValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.serviceTicketService.getMaintenanceValues()
        .subscribe({
          next: (data) => this.maintenanceValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.serviceTicketService.getServiceTicket(this.currentTicketId!)
        .subscribe({
          next: (data) => updateForm(this.editForm, data),
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const data = new ServiceTicketDTO(this.editForm.value);
    this.serviceTicketService.updateServiceTicket(this.currentTicketId!, data)
        .subscribe({
          next: () => this.router.navigate(['/serviceTickets'], {
            state: {
              msgSuccess: this.getMessage('updated')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.editForm, this.getMessage)
        });
  }

}
