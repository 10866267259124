<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@machineType.add.headline">Add Machine Type</h1>
    <div>
        <a routerLink="/machineTypes" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@machineType.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="typeName" i18n-label="@@machineType.typeName.label" label="Type Name" />
    <app-input-row [group]="addForm" field="typeDescription" rowType="textarea" i18n-label="@@machineType.typeDescription.label" label="Type Description" />
    <input type="submit" i18n-value="@@machineType.add.headline" value="Add Machine Type" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
