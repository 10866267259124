import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { MaintenanceService } from 'app/maintenance/maintenance.service';
import { MaintenanceDTO } from 'app/maintenance/maintenance.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { updateForm } from 'app/common/utils';


@Component({
  selector: 'app-maintenance-edit',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './maintenance-edit.component.html'
})
export class MaintenanceEditComponent implements OnInit {

  maintenanceService = inject(MaintenanceService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  machineTypeValues?: Map<number,string>;
  maintenanceTypeValues?: Map<number,string>;
  currentMaintenanceId?: number;

  editForm = new FormGroup({
    maintenanceId: new FormControl({ value: null, disabled: true }),
    maintenanceName: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    maintenanceDescription: new FormControl(null),
    machineType: new FormControl(null, [Validators.required]),
    maintenanceType: new FormControl(null, [Validators.required])
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      updated: $localize`:@@maintenance.update.success:Maintenance was updated successfully.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.currentMaintenanceId = +this.route.snapshot.params['maintenanceId'];
    this.maintenanceService.getMachineTypeValues()
        .subscribe({
          next: (data) => this.machineTypeValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.maintenanceService.getMaintenanceTypeValues()
        .subscribe({
          next: (data) => this.maintenanceTypeValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.maintenanceService.getMaintenance(this.currentMaintenanceId!)
        .subscribe({
          next: (data) => updateForm(this.editForm, data),
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const data = new MaintenanceDTO(this.editForm.value);
    this.maintenanceService.updateMaintenance(this.currentMaintenanceId!, data)
        .subscribe({
          next: () => this.router.navigate(['/maintenances'], {
            state: {
              msgSuccess: this.getMessage('updated')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.editForm, this.getMessage)
        });
  }

}
