import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RoleDTO } from 'app/role/role.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';


@Injectable({
  providedIn: 'root',
})
export class RoleService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/roles';

  getAllRoles(params?: Record<string,string>) {
    return this.http.get<PagedModel<RoleDTO>>(this.resourcePath, { params });
  }

  getRole(roleId: number) {
    return this.http.get<RoleDTO>(this.resourcePath + '/' + roleId);
  }

  createRole(roleDTO: RoleDTO) {
    return this.http.post<number>(this.resourcePath, roleDTO);
  }

  updateRole(roleId: number, roleDTO: RoleDTO) {
    return this.http.put<number>(this.resourcePath + '/' + roleId, roleDTO);
  }

  deleteRole(roleId: number) {
    return this.http.delete(this.resourcePath + '/' + roleId);
  }

}
