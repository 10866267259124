<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@maintenanceSchedule.add.headline">Add Maintenance Schedule</h1>
    <div>
        <a routerLink="/maintenanceSchedules" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@maintenanceSchedule.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="assignmentStatus" rowType="select" [options]="{'PENDING': 'PENDING', 'ACCEPTED': 'ACCEPTED', 'IN_PROGRESS': 'IN_PROGRESS', 'COMPLETED': 'COMPLETED', 'DECLINED': 'DECLINED', 'CANCELED': 'CANCELED'}" i18n-label="@@maintenanceSchedule.assignmentStatus.label" label="Assignment Status" />
    <app-input-row [group]="addForm" field="scheduledDate" datepicker="datepicker" i18n-label="@@maintenanceSchedule.scheduledDate.label" label="Scheduled Date" />
    <app-input-row [group]="addForm" field="scheduledStartTime" datepicker="timepicker" i18n-label="@@maintenanceSchedule.scheduledStartTime.label" label="Scheduled Start Time" />
    <app-input-row [group]="addForm" field="scheduledEndTime" datepicker="timepicker" i18n-label="@@maintenanceSchedule.scheduledEndTime.label" label="Scheduled End Time" />
    <app-input-row [group]="addForm" field="estimatedDuration" rowType="number" i18n-label="@@maintenanceSchedule.estimatedDuration.label" label="Estimated Duration" />
    <app-input-row [group]="addForm" field="notes" rowType="textarea" i18n-label="@@maintenanceSchedule.notes.label" label="Notes" />
    <app-input-row [group]="addForm" field="actualDuration" rowType="number" i18n-label="@@maintenanceSchedule.actualDuration.label" label="Actual Duration" />
    <app-input-row [group]="addForm" field="status" rowType="select" [options]="{'SCHEDULED': 'SCHEDULED', 'IN_PROGRESS': 'IN_PROGRESS', 'COMPLETED': 'COMPLETED', 'CANCELED': 'CANCELED', 'POSTPONED': 'POSTPONED', 'OVERDUE': 'OVERDUE'}" i18n-label="@@maintenanceSchedule.status.label" label="Status" />
    <app-input-row [group]="addForm" field="machine" rowType="select" [options]="machineValues" i18n-label="@@maintenanceSchedule.machine.label" label="Machine" />
    <app-input-row [group]="addForm" field="maintenance" rowType="select" [options]="maintenanceValues" i18n-label="@@maintenanceSchedule.maintenance.label" label="Maintenance" />
    <app-input-row [group]="addForm" field="assignedEmployeeEmployee" rowType="select" [options]="assignedEmployeeEmployeeValues" i18n-label="@@maintenanceSchedule.assignedEmployeeEmployee.label" label="Assigned Employee Employee" />
    <input type="submit" i18n-value="@@maintenanceSchedule.add.headline" value="Add Maintenance Schedule" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
