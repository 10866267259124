<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employeeRecurringSchedule.list.headline">Employee Recurring Schedules</h1>
    <div>
        <a routerLink="/employeeRecurringSchedules/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@employeeRecurringSchedule.list.createNew">Create new Employee Recurring Schedule</a>
    </div>
</div>
@if ((employeeRecurringSchedules && employeeRecurringSchedules.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@employeeRecurringSchedule.list.filter" placeholder="Filter by Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!employeeRecurringSchedules || employeeRecurringSchedules.page.totalElements === 0) {
<div i18n="@@employeeRecurringSchedule.list.empty">No Employee Recurring Schedules could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@employeeRecurringSchedule.id.label">Id</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeRecurringSchedule.startDate.label">Start Date</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeRecurringSchedule.endDate.label">End Date</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeRecurringSchedule.employee.label">Employee</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeRecurringSchedule.shift.label">Shift</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (employeeRecurringSchedule of employeeRecurringSchedules.content; track employeeRecurringSchedule.id) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ employeeRecurringSchedule.id }}</td>
                <td class="p-2">{{ employeeRecurringSchedule.startDate }}</td>
                <td class="p-2">{{ employeeRecurringSchedule.endDate }}</td>
                <td class="p-2">{{ employeeRecurringSchedule.employeeName }}</td>
                <td class="p-2">{{ employeeRecurringSchedule.shiftName }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/employeeRecurringSchedules/edit', employeeRecurringSchedule.id]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@employeeRecurringSchedule.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(employeeRecurringSchedule.id!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@employeeRecurringSchedule.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="employeeRecurringSchedules.page" />
}
