<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@serviceTicket.list.headline">Service Tickets</h1>
    <div>
        <a routerLink="/serviceTickets/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@serviceTicket.list.createNew">Create new Service Ticket</a>
    </div>
</div>
@if ((serviceTickets && serviceTickets.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@serviceTicket.list.filter" placeholder="Filter by Ticket Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!serviceTickets || serviceTickets.page.totalElements === 0) {
<div i18n="@@serviceTicket.list.empty">No Service Tickets could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@serviceTicket.ticketId.label">Ticket Id</th>
                <th scope="col" class="text-left p-2" i18n="@@serviceTicket.ticketStatus.label">Ticket Status</th>
                <th scope="col" class="text-left p-2" i18n="@@serviceTicket.priority.label">Priority</th>
                <th scope="col" class="text-left p-2" i18n="@@serviceTicket.assignedDate.label">Assigned Date</th>
                <th scope="col" class="text-left p-2" i18n="@@serviceTicket.resolvedDate.label">Resolved Date</th>
                <th scope="col" class="text-left p-2" i18n="@@serviceTicket.machine.label">Machine</th>
                <th scope="col" class="text-left p-2" i18n="@@serviceTicket.reportedBy.label">Reported By</th>
                <th scope="col" class="text-left p-2" i18n="@@serviceTicket.assignedTo.label">Assigned To</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (serviceTicket of serviceTickets.content; track serviceTicket.ticketId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ serviceTicket.ticketId }}</td>
                <td class="p-2">{{ serviceTicket.ticketStatus }}</td>
                <td class="p-2">{{ serviceTicket.priority }}</td>
                <td class="p-2">{{ serviceTicket.assignedDate }}</td>
                <td class="p-2">{{ serviceTicket.resolvedDate }}</td>
                <td class="p-2">{{ serviceTicket.machineName }}</td>
                <td class="p-2">{{ serviceTicket.reporterName }}</td>
                <td class="p-2">{{ serviceTicket.assigneeName }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/serviceTickets/edit', serviceTicket.ticketId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@serviceTicket.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(serviceTicket.ticketId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@serviceTicket.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="serviceTickets.page" />
}
