<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employeeShift.list.headline">Employee Shifts</h1>
    <div>
        <a routerLink="/employeeShifts/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@employeeShift.list.createNew">Create new Employee Shift</a>
    </div>
</div>
@if ((employeeShifts && employeeShifts.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@employeeShift.list.filter" placeholder="Filter by Shift Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!employeeShifts || employeeShifts.page.totalElements === 0) {
<div i18n="@@employeeShift.list.empty">No Employee Shifts could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@employeeShift.shiftId.label">Shift Id</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeShift.shiftName.label">Shift Name</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeShift.shiftStart.label">Shift Start</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeShift.shiftEnd.label">Shift End</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeShift.daysOfWeek.label">Days Of Week</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (employeeShift of employeeShifts.content; track employeeShift.shiftId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ employeeShift.shiftId }}</td>
                <td class="p-2">{{ employeeShift.shiftName }}</td>
                <td class="p-2">{{ employeeShift.shiftStart }}</td>
                <td class="p-2">{{ employeeShift.shiftEnd }}</td>
                <td class="p-2">{{ employeeShift.daysOfWeek }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/employeeShifts/edit', employeeShift.shiftId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@employeeShift.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(employeeShift.shiftId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@employeeShift.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="employeeShifts.page" />
}
