<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@serviceTicket.add.headline">Add Service Ticket</h1>
    <div>
        <a routerLink="/serviceTickets" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@serviceTicket.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="issueDescription" rowType="textarea" i18n-label="@@serviceTicket.issueDescription.label" label="Issue Description" />
    <app-input-row [group]="addForm" field="ticketStatus" rowType="select" [options]="{'OPEN': 'OPEN', 'TO_DO': 'TO_DO', 'IN_PROGRESS': 'IN_PROGRESS', 'DONE': 'DONE', 'IN_REVIEW': 'IN_REVIEW', 'REOPENED': 'REOPENED', 'BLOCKED': 'BLOCKED', 'CLOSED': 'CLOSED', 'CANCELED': 'CANCELED'}" i18n-label="@@serviceTicket.ticketStatus.label" label="Ticket Status" />
    <app-input-row [group]="addForm" field="priority" rowType="select" [options]="{'LOW': 'LOW', 'MEDIUM': 'MEDIUM', 'HIGH': 'HIGH', 'CRITICAL': 'CRITICAL'}" i18n-label="@@serviceTicket.priority.label" label="Priority" />
    <app-input-row [group]="addForm" field="taskPhotoProofUrl" rowType="textarea" i18n-label="@@serviceTicket.taskPhotoProofUrl.label" label="Task Photo Proof Url" />
    <app-input-row [group]="addForm" field="assignedDate" i18n-label="@@serviceTicket.assignedDate.label" label="Assigned Date" />
    <app-input-row [group]="addForm" field="resolvedDate" i18n-label="@@serviceTicket.resolvedDate.label" label="Resolved Date" />
    <app-input-row [group]="addForm" field="machine" rowType="select" [options]="machineValues" i18n-label="@@serviceTicket.machine.label" label="Machine" />
    <app-input-row [group]="addForm" field="reportedBy" rowType="select" [options]="reportedByValues" i18n-label="@@serviceTicket.reportedBy.label" label="Reported By" />
    <app-input-row [group]="addForm" field="assignedTo" rowType="select" [options]="assignedToValues" i18n-label="@@serviceTicket.assignedTo.label" label="Assigned To" />
    <app-input-row [group]="addForm" field="maintenance" rowType="select" [options]="maintenanceValues" i18n-label="@@serviceTicket.maintenance.label" label="Maintenance" />
    <input type="submit" i18n-value="@@serviceTicket.add.headline" value="Add Service Ticket" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
