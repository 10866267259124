<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@maintenanceTask.list.headline">Maintenance Tasks</h1>
    <div>
        <a routerLink="/maintenanceTasks/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@maintenanceTask.list.createNew">Create new Maintenance Task</a>
    </div>
</div>
@if ((maintenanceTasks && maintenanceTasks.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@maintenanceTask.list.filter" placeholder="Filter by Task Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!maintenanceTasks || maintenanceTasks.page.totalElements === 0) {
<div i18n="@@maintenanceTask.list.empty">No Maintenance Tasks could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceTask.taskId.label">Task Id</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceTask.taskName.label">Task Name</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceTask.taskOrder.label">Task Order</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceTask.maintenance.label">Maintenance</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (maintenanceTask of maintenanceTasks.content; track maintenanceTask.taskId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ maintenanceTask.taskId }}</td>
                <td class="p-2">{{ maintenanceTask.taskName }}</td>
                <td class="p-2">{{ maintenanceTask.taskOrder }}</td>
                <td class="p-2">{{ maintenanceTask.maintenanceName }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/maintenanceTasks/edit', maintenanceTask.taskId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@maintenanceTask.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(maintenanceTask.taskId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@maintenanceTask.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="maintenanceTasks.page" />
}
