<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employeeShift.edit.headline">Edit Employee Shift</h1>
    <div>
        <a routerLink="/employeeShifts" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@employeeShift.edit.back">Back to list</a>
    </div>
</div>
<form [formGroup]="editForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="editForm" field="shiftId" rowType="number" i18n-label="@@employeeShift.shiftId.label" label="Shift Id" />
    <app-input-row [group]="editForm" field="shiftName" i18n-label="@@employeeShift.shiftName.label" label="Shift Name" />
    <app-input-row [group]="editForm" field="shiftStart" datepicker="timepicker" i18n-label="@@employeeShift.shiftStart.label" label="Shift Start" />
    <app-input-row [group]="editForm" field="shiftEnd" datepicker="timepicker" i18n-label="@@employeeShift.shiftEnd.label" label="Shift End" />
    <app-input-row [group]="editForm" field="daysOfWeek" rowType="select" [options]="{'MONDAY': 'MONDAY', 'TUESDAY': 'TUESDAY', 'WEDNESDAY': 'WEDNESDAY', 'THURSDAY': 'THURSDAY', 'FRIDAY': 'FRIDAY', 'SATURDAY': 'SATURDAY', 'SUNDAY': 'SUNDAY'}" i18n-label="@@employeeShift.daysOfWeek.label" label="Days Of Week" />
    <app-input-row [group]="editForm" field="description" rowType="textarea" i18n-label="@@employeeShift.description.label" label="Description" />
    <input type="submit" i18n-value="@@employeeShift.edit.headline" value="Edit Employee Shift" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
