export class MachineCategoryDTO {

  constructor(data:Partial<MachineCategoryDTO>) {
    Object.assign(this, data);
  }

  categoryId?: number|null;
  categoryName?: string|null;
  categoryDescription?: string|null;

}
