import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { MachineService } from 'app/machine/machine.service';
import { MachineDTO } from 'app/machine/machine.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { updateForm } from 'app/common/utils';


@Component({
  selector: 'app-machine-edit',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './machine-edit.component.html'
})
export class MachineEditComponent implements OnInit {

  machineService = inject(MachineService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  machineTypeValues?: Map<number,string>;
  machineCategoryValues?: Map<number,string>;
  locationValues?: Map<number,string>;
  currentMachineId?: number;

  editForm = new FormGroup({
    machineId: new FormControl({ value: null, disabled: true }),
    serialNumber: new FormControl(null, [Validators.maxLength(255)]),
    machineName: new FormControl(null, [Validators.maxLength(255)]),
    machineQrcode: new FormControl(null),
    description: new FormControl(null),
    installationDate: new FormControl(null),
    lastServicedDate: new FormControl(null),
    isOperational: new FormControl(false),
    status: new FormControl(null, [Validators.required]),
    machineType: new FormControl(null, [Validators.required]),
    machineCategory: new FormControl(null, [Validators.required]),
    location: new FormControl(null)
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      updated: $localize`:@@machine.update.success:Machine was updated successfully.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.currentMachineId = +this.route.snapshot.params['machineId'];
    this.machineService.getMachineTypeValues()
        .subscribe({
          next: (data) => this.machineTypeValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.machineService.getMachineCategoryValues()
        .subscribe({
          next: (data) => this.machineCategoryValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.machineService.getLocationValues()
        .subscribe({
          next: (data) => this.locationValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.machineService.getMachine(this.currentMachineId!)
        .subscribe({
          next: (data) => updateForm(this.editForm, data),
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const data = new MachineDTO(this.editForm.value);
    this.machineService.updateMachine(this.currentMachineId!, data)
        .subscribe({
          next: () => this.router.navigate(['/machines'], {
            state: {
              msgSuccess: this.getMessage('updated')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.editForm, this.getMessage)
        });
  }

}
