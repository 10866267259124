<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@maintenanceTaskLog.edit.headline">Edit Maintenance Task Log</h1>
    <div>
        <a routerLink="/maintenanceTaskLogs" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@maintenanceTaskLog.edit.back">Back to list</a>
    </div>
</div>
<form [formGroup]="editForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="editForm" field="taskId" rowType="number" i18n-label="@@maintenanceTaskLog.taskId.label" label="Task Id" />
    <app-input-row [group]="editForm" field="taskName" i18n-label="@@maintenanceTaskLog.taskName.label" label="Task Name" />
    <app-input-row [group]="editForm" field="description" rowType="textarea" i18n-label="@@maintenanceTaskLog.description.label" label="Description" />
    <app-input-row [group]="editForm" field="taskOrder" rowType="number" i18n-label="@@maintenanceTaskLog.taskOrder.label" label="Task Order" />
    <app-input-row [group]="editForm" field="taskStatus" rowType="select" [options]="{'SCHEDULED': 'SCHEDULED', 'IN_PROGRESS': 'IN_PROGRESS', 'COMPLETED': 'COMPLETED', 'CANCELED': 'CANCELED', 'POSTPONED': 'POSTPONED', 'OVERDUE': 'OVERDUE'}" i18n-label="@@maintenanceTaskLog.taskStatus.label" label="Task Status" />
    <app-input-row [group]="editForm" field="taskPhotoProofUrl" rowType="textarea" i18n-label="@@maintenanceTaskLog.taskPhotoProofUrl.label" label="Task Photo Proof Url" />
    <app-input-row [group]="editForm" field="maintenance" rowType="select" [options]="maintenanceValues" i18n-label="@@maintenanceTaskLog.maintenance.label" label="Maintenance" />
    <app-input-row [group]="editForm" field="assignedEmployeeEmail" rowType="select" [options]="assignedEmployeeEmailValues" i18n-label="@@maintenanceTaskLog.assignedEmployeeEmail.label" label="Assigned Employee Email" />
    <app-input-row [group]="editForm" field="machine" rowType="select" [options]="machineValues" i18n-label="@@maintenanceTaskLog.machine.label" label="Machine" />
    <input type="submit" i18n-value="@@maintenanceTaskLog.edit.headline" value="Edit Maintenance Task Log" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
