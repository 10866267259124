import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { EmployeeTimeOffService } from 'app/employee-time-off/employee-time-off.service';
import { EmployeeTimeOffDTO } from 'app/employee-time-off/employee-time-off.model';
import { SearchFilterComponent } from 'app/common/list-helper/search-filter.component';
import { SortingComponent } from 'app/common/list-helper/sorting.component';
import { getListParams } from 'app/common/utils';
import { PagedModel, PaginationComponent } from 'app/common/list-helper/pagination.component';


@Component({
  selector: 'app-employee-time-off-list',
  standalone: true,
  imports: [CommonModule, SearchFilterComponent ,SortingComponent, PaginationComponent, RouterLink],
  templateUrl: './employee-time-off-list.component.html'})
export class EmployeeTimeOffListComponent implements OnInit, OnDestroy {

  employeeTimeOffService = inject(EmployeeTimeOffService);
  errorHandler = inject(ErrorHandler);
  route = inject(ActivatedRoute);
  router = inject(Router);
  employeeTimeOffs?: PagedModel<EmployeeTimeOffDTO>;
  navigationSubscription?: Subscription;

  sortOptions = {
    'id,ASC': $localize`:@@employeeTimeOff.list.sort.id,ASC:Sort by Id (Ascending)`, 
    'startDate,ASC': $localize`:@@employeeTimeOff.list.sort.startDate,ASC:Sort by Start Date (Ascending)`, 
    'endDate,ASC': $localize`:@@employeeTimeOff.list.sort.endDate,ASC:Sort by End Date (Ascending)`
  }

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      confirm: $localize`:@@delete.confirm:Do you really want to delete this element? This cannot be undone.`,
      deleted: $localize`:@@employeeTimeOff.delete.success:Employee Time Off was removed successfully.`    };
    return messages[key];
  }

  ngOnInit() {
    this.loadData();
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadData();
      }
    });
  }

  ngOnDestroy() {
    this.navigationSubscription!.unsubscribe();
  }
  
  loadData() {
    this.employeeTimeOffService.getAllEmployeeTimeOffs(getListParams(this.route))
        .subscribe({
          next: (data) => this.employeeTimeOffs = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  confirmDelete(id: number) {
    if (confirm(this.getMessage('confirm'))) {
      this.employeeTimeOffService.deleteEmployeeTimeOff(id)
          .subscribe({
            next: () => this.router.navigate(['/employeeTimeOffs'], {
              state: {
                msgInfo: this.getMessage('deleted')
              }
            }),
            error: (error) => this.errorHandler.handleServerError(error.error)
          });
    }
  }

}
