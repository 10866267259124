export class EmployeeDTO {

  constructor(data:Partial<EmployeeDTO>) {
    Object.assign(this, data);
  }

  employeeId?: number|null;
  firstName?: string|null;
  lastName?: string|null;
  email?: string|null;
  phoneNumber?: string|null;
  password?: string|null;
  externalId?: string|null;
  isActive?: boolean|null;
  roles?: number[]|null;

}
