export class MaintenanceScheduleDTO {

  constructor(data:Partial<MaintenanceScheduleDTO>) {
    Object.assign(this, data);
  }

  scheduleId?: number|null;
  assignmentStatus?: string|null;
  scheduledDate?: string|null;
  scheduledStartTime?: string|null;
  scheduledEndTime?: string|null;
  estimatedDuration?: number|null;
  notes?: string|null;
  actualDuration?: number|null;
  status?: string|null;
  machine?: number|null;
  maintenance?: number|null;
  assignedEmployeeEmployee?: number|null;

}
