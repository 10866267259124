<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@maintenanceSchedule.list.headline">Maintenance Schedules</h1>
    <div>
        <a routerLink="/maintenanceSchedules/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@maintenanceSchedule.list.createNew">Create new Maintenance Schedule</a>
    </div>
</div>
@if ((maintenanceSchedules && maintenanceSchedules.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@maintenanceSchedule.list.filter" placeholder="Filter by Schedule Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!maintenanceSchedules || maintenanceSchedules.page.totalElements === 0) {
<div i18n="@@maintenanceSchedule.list.empty">No Maintenance Schedules could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceSchedule.scheduleId.label">Schedule Id</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceSchedule.assignmentStatus.label">Assignment Status</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceSchedule.scheduledDate.label">Scheduled Date</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceSchedule.scheduledStartTime.label">Scheduled Start Time</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceSchedule.scheduledEndTime.label">Scheduled End Time</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceSchedule.estimatedDuration.label">Estimated Duration</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceSchedule.actualDuration.label">Actual Duration</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceSchedule.status.label">Status</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (maintenanceSchedule of maintenanceSchedules.content; track maintenanceSchedule.scheduleId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ maintenanceSchedule.scheduleId }}</td>
                <td class="p-2">{{ maintenanceSchedule.assignmentStatus }}</td>
                <td class="p-2">{{ maintenanceSchedule.scheduledDate }}</td>
                <td class="p-2">{{ maintenanceSchedule.scheduledStartTime }}</td>
                <td class="p-2">{{ maintenanceSchedule.scheduledEndTime }}</td>
                <td class="p-2">{{ maintenanceSchedule.estimatedDuration }}</td>
                <td class="p-2">{{ maintenanceSchedule.actualDuration }}</td>
                <td class="p-2">{{ maintenanceSchedule.status }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/maintenanceSchedules/edit', maintenanceSchedule.scheduleId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@maintenanceSchedule.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(maintenanceSchedule.scheduleId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@maintenanceSchedule.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="maintenanceSchedules.page" />
}
