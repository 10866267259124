import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MaintenanceTaskLogDTO } from 'app/maintenance-task-log/maintenance-task-log.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';
import { map } from 'rxjs';
import { transformRecordToMap } from 'app/common/utils';


@Injectable({
  providedIn: 'root',
})
export class MaintenanceTaskLogService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/maintenanceTaskLogs';

  getAllMaintenanceTaskLogs(params?: Record<string,string>) {
    return this.http.get<PagedModel<MaintenanceTaskLogDTO>>(this.resourcePath, { params });
  }

  getMaintenanceTaskLog(taskId: number) {
    return this.http.get<MaintenanceTaskLogDTO>(this.resourcePath + '/' + taskId);
  }

  createMaintenanceTaskLog(maintenanceTaskLogDTO: MaintenanceTaskLogDTO) {
    return this.http.post<number>(this.resourcePath, maintenanceTaskLogDTO);
  }

  updateMaintenanceTaskLog(taskId: number, maintenanceTaskLogDTO: MaintenanceTaskLogDTO) {
    return this.http.put<number>(this.resourcePath + '/' + taskId, maintenanceTaskLogDTO);
  }

  deleteMaintenanceTaskLog(taskId: number) {
    return this.http.delete(this.resourcePath + '/' + taskId);
  }

  getMaintenanceValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/maintenanceValues')
        .pipe(map(transformRecordToMap));
  }

  getAssignedEmployeeEmailValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/assignedEmployeeEmailValues')
        .pipe(map(transformRecordToMap));
  }

  getMachineValues() {
    return this.http.get<Record<string,number>>(this.resourcePath + '/machineValues')
        .pipe(map(transformRecordToMap));
  }

}
