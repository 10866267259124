export class DepartmentDTO {

  constructor(data:Partial<DepartmentDTO>) {
    Object.assign(this, data);
  }

  departmentId?: number|null;
  departmentName?: string|null;
  departmentDescription?: string|null;

}
