<app-header />

<main class="my-12">
    <div class="container px-4 md:px-6">
        @if (msgSuccess) { <p class="bg-green-200 border-green-800 text-green-800 border rounded p-4 mb-6" role="alert">{{ msgSuccess }}</p> }
        @if (msgInfo) { <p class="bg-blue-200 border-blue-800 text-blue-800 border rounded p-4 mb-6" role="alert">{{ msgInfo }}</p> }
        @if (msgError) { <p class="bg-red-200 border-red-800 text-red-800 border rounded p-4 mb-6" role="alert">{{ msgError }}</p> }
        <router-outlet />
    </div>
</main>
