<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employeeProfile.edit.headline">Edit Employee Profile</h1>
    <div>
        <a routerLink="/employeeProfiles" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@employeeProfile.edit.back">Back to list</a>
    </div>
</div>
<form [formGroup]="editForm" (ngSubmit)="handleSubmit()">
    <input type="submit" i18n-value="@@employeeProfile.edit.headline" value="Edit Employee Profile" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6 mb-5" />
    <app-input-row [group]="editForm" field="profileId" rowType="number" i18n-label="@@employeeProfile.profileId.label" label="Profile Id" />
    <app-input-row [group]="editForm" field="jobTitle" i18n-label="@@employeeProfile.jobTitle.label" label="Job Title" />
    <app-input-row [group]="editForm" field="hireDate" datepicker="datepicker" i18n-label="@@employeeProfile.hireDate.label" label="Hire Date" />
    <app-input-row [group]="editForm" field="gender" rowType="radio" [options]="{'MALE': 'MALE', 'FEMALE': 'FEMALE', 'OTHER': 'OTHER'}" i18n-label="@@employeeProfile.gender.label" label="Gender" />
    <app-input-row [group]="editForm" field="birthdate" datepicker="datepicker" i18n-label="@@employeeProfile.birthdate.label" label="Birthdate" />
    <app-input-row [group]="editForm" field="driverLicense" i18n-label="@@employeeProfile.driverLicense.label" label="Driver License" />
    <app-input-row [group]="editForm" field="vehicleDetails" i18n-label="@@employeeProfile.vehicleDetails.label" label="Vehicle Details" />
    <app-input-row [group]="editForm" field="addressLine1" i18n-label="@@employeeProfile.addressLine1.label" label="Address Line1" />
    <app-input-row [group]="editForm" field="addressLine2" i18n-label="@@employeeProfile.addressLine2.label" label="Address Line2" />
    <app-input-row [group]="editForm" field="landmark" i18n-label="@@employeeProfile.landmark.label" label="Landmark" />
    <app-input-row [group]="editForm" field="notes" rowType="textarea" i18n-label="@@employeeProfile.notes.label" label="Notes" />
    <app-input-row [group]="editForm" field="postCode" i18n-label="@@employeeProfile.postCode.label" label="Post Code" />
    <app-input-row [group]="editForm" field="city" i18n-label="@@employeeProfile.city.label" label="City" />
    <app-input-row [group]="editForm" field="state" i18n-label="@@employeeProfile.state.label" label="State" />
    <app-input-row [group]="editForm" field="country" i18n-label="@@employeeProfile.country.label" label="Country" />
    <app-input-row [group]="editForm" field="latitude" i18n-label="@@employeeProfile.latitude.label" label="Latitude" />
    <app-input-row [group]="editForm" field="longitude" i18n-label="@@employeeProfile.longitude.label" label="Longitude" />
    <app-input-row [group]="editForm" field="plusCode" i18n-label="@@employeeProfile.plusCode.label" label="Plus Code" />
    <app-input-row [group]="editForm" field="profilePicUrl" i18n-label="@@employeeProfile.profilePicUrl.label" label="Profile Pic Url" />
    <app-input-row [group]="editForm" field="employee" rowType="select" [options]="employeeValues" i18n-label="@@employeeProfile.employee.label" label="Employee" />
    <app-input-row [group]="editForm" field="department" rowType="select" [options]="departmentValues" i18n-label="@@employeeProfile.department.label" label="Department" />
    <input type="submit" i18n-value="@@employeeProfile.edit.headline" value="Edit Employee Profile" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
