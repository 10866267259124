import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { TicketCommentsService } from 'app/ticket-comments/ticket-comments.service';
import { TicketCommentsDTO } from 'app/ticket-comments/ticket-comments.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { updateForm } from 'app/common/utils';


@Component({
  selector: 'app-ticket-comments-edit',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './ticket-comments-edit.component.html'
})
export class TicketCommentsEditComponent implements OnInit {

  ticketCommentsService = inject(TicketCommentsService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  ticketValues?: Map<number,string>;
  commentedByValues?: Map<number,string>;
  currentCommentId?: number;

  editForm = new FormGroup({
    commentId: new FormControl({ value: null, disabled: true }),
    commentText: new FormControl(null, [Validators.required]),
    ticket: new FormControl(null, [Validators.required]),
    commentedBy: new FormControl(null, [Validators.required])
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      updated: $localize`:@@ticketComments.update.success:Ticket Comments was updated successfully.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.currentCommentId = +this.route.snapshot.params['commentId'];
    this.ticketCommentsService.getTicketValues()
        .subscribe({
          next: (data) => this.ticketValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.ticketCommentsService.getCommentedByValues()
        .subscribe({
          next: (data) => this.commentedByValues = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
    this.ticketCommentsService.getTicketComments(this.currentCommentId!)
        .subscribe({
          next: (data) => updateForm(this.editForm, data),
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const data = new TicketCommentsDTO(this.editForm.value);
    this.ticketCommentsService.updateTicketComments(this.currentCommentId!, data)
        .subscribe({
          next: () => this.router.navigate(['/ticketCommentss'], {
            state: {
              msgSuccess: this.getMessage('updated')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.editForm, this.getMessage)
        });
  }

}
