<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@machineCategory.edit.headline">Edit Machine Category</h1>
    <div>
        <a routerLink="/machineCategories" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@machineCategory.edit.back">Back to list</a>
    </div>
</div>
<form [formGroup]="editForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="editForm" field="categoryId" rowType="number" i18n-label="@@machineCategory.categoryId.label" label="Category Id" />
    <app-input-row [group]="editForm" field="categoryName" i18n-label="@@machineCategory.categoryName.label" label="Category Name" />
    <app-input-row [group]="editForm" field="categoryDescription" rowType="textarea" i18n-label="@@machineCategory.categoryDescription.label" label="Category Description" />
    <input type="submit" i18n-value="@@machineCategory.edit.headline" value="Edit Machine Category" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
