import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { EmployeeShiftService } from 'app/employee-shift/employee-shift.service';
import { EmployeeShiftDTO } from 'app/employee-shift/employee-shift.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';


@Component({
  selector: 'app-employee-shift-add',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './employee-shift-add.component.html'
})
export class EmployeeShiftAddComponent {

  employeeShiftService = inject(EmployeeShiftService);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  addForm = new FormGroup({
    shiftName: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    shiftStart: new FormControl(null, [Validators.required]),
    shiftEnd: new FormControl(null, [Validators.required]),
    daysOfWeek: new FormControl(null, [Validators.required]),
    description: new FormControl(null)
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      created: $localize`:@@employeeShift.create.success:Employee Shift was created successfully.`
    };
    return messages[key];
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.addForm.markAllAsTouched();
    if (!this.addForm.valid) {
      return;
    }
    const data = new EmployeeShiftDTO(this.addForm.value);
    this.employeeShiftService.createEmployeeShift(data)
        .subscribe({
          next: () => this.router.navigate(['/employeeShifts'], {
            state: {
              msgSuccess: this.getMessage('created')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.addForm, this.getMessage)
        });
  }

}
