<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@department.add.headline">Add Department</h1>
    <div>
        <a routerLink="/departments" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@department.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="departmentName" i18n-label="@@department.departmentName.label" label="Department Name" />
    <app-input-row [group]="addForm" field="departmentDescription" rowType="textarea" i18n-label="@@department.departmentDescription.label" label="Department Description" />
    <input type="submit" i18n-value="@@department.add.headline" value="Add Department" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
