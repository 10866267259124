export class MachineDTO {

  constructor(data:Partial<MachineDTO>) {
    Object.assign(this, data);
  }

  machineId?: number|null;
  serialNumber?: string|null;
  machineName?: string|null;
  machineQrcode?: string|null;
  description?: string|null;
  installationDate?: string|null;
  lastServicedDate?: string|null;
  isOperational?: boolean|null;
  status?: string|null;
  machineType?: number|null;
  machineTypeName?: string|null;
  machineCategory?: number|null;
  machineCategoryName?: string|null;
  location?: number|null;

}
