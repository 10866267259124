<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@machineLocation.list.headline">Machine Locations</h1>
    <div>
        <a routerLink="/machineLocations/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@machineLocation.list.createNew">Create new Machine Location</a>
    </div>
</div>
@if ((machineLocations && machineLocations.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@machineLocation.list.filter" placeholder="Filter by Location Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!machineLocations || machineLocations.page.totalElements === 0) {
<div i18n="@@machineLocation.list.empty">No Machine Locations could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@machineLocation.locationId.label">Location Id</th>
                <th scope="col" class="text-left p-2" i18n="@@machineLocation.locationName.label">Location Name</th>
                <th scope="col" class="text-left p-2" i18n="@@machineLocation.locationType.label">Location Type</th>
                <th scope="col" class="text-left p-2" i18n="@@machineLocation.officialName.label">Official Name</th>
                <th scope="col" class="text-left p-2" i18n="@@machineLocation.phone.label">Phone</th>
                <th scope="col" class="text-left p-2" i18n="@@machineLocation.email.label">Email</th>
                <th scope="col" class="text-left p-2" i18n="@@machineLocation.isActive.label">Is Active</th>
                <th scope="col" class="text-left p-2" i18n="@@machineLocation.openingHours.label">Opening Hours</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (machineLocation of machineLocations.content; track machineLocation.locationId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ machineLocation.locationId }}</td>
                <td class="p-2">{{ machineLocation.locationName }}</td>
                <td class="p-2">{{ machineLocation.locationType }}</td>
                <td class="p-2">{{ machineLocation.officialName }}</td>
                <td class="p-2">{{ machineLocation.phone }}</td>
                <td class="p-2">{{ machineLocation.email }}</td>
                <td class="p-2">{{ machineLocation.isActive }}</td>
                <td class="p-2">{{ machineLocation.openingHours }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/machineLocations/edit', machineLocation.locationId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@machineLocation.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(machineLocation.locationId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@machineLocation.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="machineLocations.page" />
}
