import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { EmployeeDTO } from 'app/employee/employee.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';
import { map } from 'rxjs';
import { transformRecordToMap } from 'app/common/utils';


@Injectable({
  providedIn: 'root',
})
export class EmployeeService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/employees';

  getAllEmployees(params?: Record<string,string>) {
    return this.http.get<PagedModel<EmployeeDTO>>(this.resourcePath, { params });
  }

  getEmployee(employeeId: number) {
    return this.http.get<EmployeeDTO>(this.resourcePath + '/' + employeeId);
  }

  createEmployee(employeeDTO: EmployeeDTO) {
    return this.http.post<number>(this.resourcePath, employeeDTO);
  }

  updateEmployee(employeeId: number, employeeDTO: EmployeeDTO) {
    return this.http.put<number>(this.resourcePath + '/' + employeeId, employeeDTO);
  }

  deleteEmployee(employeeId: number) {
    return this.http.delete(this.resourcePath + '/' + employeeId);
  }

  getRolesValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/rolesValues')
        .pipe(map(transformRecordToMap));
  }

}
