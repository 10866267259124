<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@maintenanceType.list.headline">Maintenance Types</h1>
    <div>
        <a routerLink="/maintenanceTypes/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@maintenanceType.list.createNew">Create new Maintenance Type</a>
    </div>
</div>
@if ((maintenanceTypes && maintenanceTypes.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@maintenanceType.list.filter" placeholder="Filter by Type Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!maintenanceTypes || maintenanceTypes.page.totalElements === 0) {
<div i18n="@@maintenanceType.list.empty">No Maintenance Types could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceType.typeId.label">Type Id</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceType.typeName.label">Type Name</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (maintenanceType of maintenanceTypes.content; track maintenanceType.typeId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ maintenanceType.typeId }}</td>
                <td class="p-2">{{ maintenanceType.typeName }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/maintenanceTypes/edit', maintenanceType.typeId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@maintenanceType.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(maintenanceType.typeId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@maintenanceType.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="maintenanceTypes.page" />
}
