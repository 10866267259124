export class EmployeeShiftDTO {

  constructor(data:Partial<EmployeeShiftDTO>) {
    Object.assign(this, data);
  }

  shiftId?: number|null;
  shiftName?: string|null;
  shiftStart?: string|null;
  shiftEnd?: string|null;
  daysOfWeek?: string|null;
  description?: string|null;

}
