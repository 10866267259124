import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { EmployeeShiftService } from 'app/employee-shift/employee-shift.service';
import { EmployeeShiftDTO } from 'app/employee-shift/employee-shift.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { updateForm } from 'app/common/utils';


@Component({
  selector: 'app-employee-shift-edit',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './employee-shift-edit.component.html'
})
export class EmployeeShiftEditComponent implements OnInit {

  employeeShiftService = inject(EmployeeShiftService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  currentShiftId?: number;

  editForm = new FormGroup({
    shiftId: new FormControl({ value: null, disabled: true }),
    shiftName: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    shiftStart: new FormControl(null, [Validators.required]),
    shiftEnd: new FormControl(null, [Validators.required]),
    daysOfWeek: new FormControl(null, [Validators.required]),
    description: new FormControl(null)
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      updated: $localize`:@@employeeShift.update.success:Employee Shift was updated successfully.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.currentShiftId = +this.route.snapshot.params['shiftId'];
    this.employeeShiftService.getEmployeeShift(this.currentShiftId!)
        .subscribe({
          next: (data) => updateForm(this.editForm, data),
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const data = new EmployeeShiftDTO(this.editForm.value);
    this.employeeShiftService.updateEmployeeShift(this.currentShiftId!, data)
        .subscribe({
          next: () => this.router.navigate(['/employeeShifts'], {
            state: {
              msgSuccess: this.getMessage('updated')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.editForm, this.getMessage)
        });
  }

}
