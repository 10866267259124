import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MaintenanceTaskDTO } from 'app/maintenance-task/maintenance-task.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';
import { map } from 'rxjs';
import { transformRecordToMap } from 'app/common/utils';


@Injectable({
  providedIn: 'root',
})
export class MaintenanceTaskService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/maintenanceTasks';

  getAllMaintenanceTasks(params?: Record<string,string>) {
    return this.http.get<PagedModel<MaintenanceTaskDTO>>(this.resourcePath, { params });
  }

  getMaintenanceTask(taskId: number) {
    return this.http.get<MaintenanceTaskDTO>(this.resourcePath + '/' + taskId);
  }

  createMaintenanceTask(maintenanceTaskDTO: MaintenanceTaskDTO) {
    return this.http.post<number>(this.resourcePath, maintenanceTaskDTO);
  }

  updateMaintenanceTask(taskId: number, maintenanceTaskDTO: MaintenanceTaskDTO) {
    return this.http.put<number>(this.resourcePath + '/' + taskId, maintenanceTaskDTO);
  }

  deleteMaintenanceTask(taskId: number) {
    return this.http.delete(this.resourcePath + '/' + taskId);
  }

  getMaintenanceValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/maintenanceValues')
        .pipe(map(transformRecordToMap));
  }

}
