import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputRowComponent } from 'app/common/input-row/input-row.component';
import { MaintenanceTypeService } from 'app/maintenance-type/maintenance-type.service';
import { MaintenanceTypeDTO } from 'app/maintenance-type/maintenance-type.model';
import { ErrorHandler } from 'app/common/error-handler.injectable';


@Component({
  selector: 'app-maintenance-type-add',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, InputRowComponent],
  templateUrl: './maintenance-type-add.component.html'
})
export class MaintenanceTypeAddComponent {

  maintenanceTypeService = inject(MaintenanceTypeService);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);

  addForm = new FormGroup({
    typeName: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    typeDescription: new FormControl(null)
  }, { updateOn: 'submit' });

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      created: $localize`:@@maintenanceType.create.success:Maintenance Type was created successfully.`
    };
    return messages[key];
  }

  handleSubmit() {
    window.scrollTo(0, 0);
    this.addForm.markAllAsTouched();
    if (!this.addForm.valid) {
      return;
    }
    const data = new MaintenanceTypeDTO(this.addForm.value);
    this.maintenanceTypeService.createMaintenanceType(data)
        .subscribe({
          next: () => this.router.navigate(['/maintenanceTypes'], {
            state: {
              msgSuccess: this.getMessage('created')
            }
          }),
          error: (error) => this.errorHandler.handleServerError(error.error, this.addForm, this.getMessage)
        });
  }

}
