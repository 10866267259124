export class MachineLocationDTO {

  constructor(data:Partial<MachineLocationDTO>) {
    Object.assign(this, data);
  }

  locationId?: number|null;
  locationName?: string|null;
  locationType?: string|null;
  locationDescription?: string|null;
  officialName?: string|null;
  phone?: string|null;
  email?: string|null;
  isActive?: boolean|null;
  openingHours?: string|null;
  addressLine1?: string|null;
  addressLine2?: string|null;
  landmark?: string|null;
  notes?: string|null;
  postCode?: string|null;
  city?: string|null;
  state?: string|null;
  country?: string|null;
  latitude?: string|null;
  longitude?: string|null;
  plusCode?: string|null;

}
