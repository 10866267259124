import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DepartmentListComponent } from './department/department-list.component';
import { DepartmentAddComponent } from './department/department-add.component';
import { DepartmentEditComponent } from './department/department-edit.component';
import { RoleListComponent } from './role/role-list.component';
import { RoleAddComponent } from './role/role-add.component';
import { RoleEditComponent } from './role/role-edit.component';
import { EmployeeListComponent } from './employee/employee-list.component';
import { EmployeeAddComponent } from './employee/employee-add.component';
import { EmployeeEditComponent } from './employee/employee-edit.component';
import { EmployeeProfileListComponent } from './employee-profile/employee-profile-list.component';
import { EmployeeProfileAddComponent } from './employee-profile/employee-profile-add.component';
import { EmployeeProfileEditComponent } from './employee-profile/employee-profile-edit.component';
import { MachineLocationListComponent } from './machine-location/machine-location-list.component';
import { MachineLocationAddComponent } from './machine-location/machine-location-add.component';
import { MachineLocationEditComponent } from './machine-location/machine-location-edit.component';
import { MachineTypeListComponent } from './machine-type/machine-type-list.component';
import { MachineTypeAddComponent } from './machine-type/machine-type-add.component';
import { MachineTypeEditComponent } from './machine-type/machine-type-edit.component';
import { MachineCategoryListComponent } from './machine-category/machine-category-list.component';
import { MachineCategoryAddComponent } from './machine-category/machine-category-add.component';
import { MachineCategoryEditComponent } from './machine-category/machine-category-edit.component';
import { MaintenanceTypeListComponent } from './maintenance-type/maintenance-type-list.component';
import { MaintenanceTypeAddComponent } from './maintenance-type/maintenance-type-add.component';
import { MaintenanceTypeEditComponent } from './maintenance-type/maintenance-type-edit.component';
import { MaintenanceListComponent } from './maintenance/maintenance-list.component';
import { MaintenanceAddComponent } from './maintenance/maintenance-add.component';
import { MaintenanceEditComponent } from './maintenance/maintenance-edit.component';
import { MaintenanceTaskListComponent } from './maintenance-task/maintenance-task-list.component';
import { MaintenanceTaskAddComponent } from './maintenance-task/maintenance-task-add.component';
import { MaintenanceTaskEditComponent } from './maintenance-task/maintenance-task-edit.component';
import { MachineListComponent } from './machine/machine-list.component';
import { MachineAddComponent } from './machine/machine-add.component';
import { MachineEditComponent } from './machine/machine-edit.component';
import { EmployeeTimeOffListComponent } from './employee-time-off/employee-time-off-list.component';
import { EmployeeTimeOffAddComponent } from './employee-time-off/employee-time-off-add.component';
import { EmployeeTimeOffEditComponent } from './employee-time-off/employee-time-off-edit.component';
import { EmployeeShiftListComponent } from './employee-shift/employee-shift-list.component';
import { EmployeeShiftAddComponent } from './employee-shift/employee-shift-add.component';
import { EmployeeShiftEditComponent } from './employee-shift/employee-shift-edit.component';
import { EmployeeScheduleListComponent } from './employee-schedule/employee-schedule-list.component';
import { EmployeeScheduleAddComponent } from './employee-schedule/employee-schedule-add.component';
import { EmployeeScheduleEditComponent } from './employee-schedule/employee-schedule-edit.component';
import { EmployeeRecurringScheduleListComponent } from './employee-recurring-schedule/employee-recurring-schedule-list.component';
import { EmployeeRecurringScheduleAddComponent } from './employee-recurring-schedule/employee-recurring-schedule-add.component';
import { EmployeeRecurringScheduleEditComponent } from './employee-recurring-schedule/employee-recurring-schedule-edit.component';
import { MaintenanceScheduleListComponent } from './maintenance-schedule/maintenance-schedule-list.component';
import { MaintenanceScheduleAddComponent } from './maintenance-schedule/maintenance-schedule-add.component';
import { MaintenanceScheduleEditComponent } from './maintenance-schedule/maintenance-schedule-edit.component';
import { MaintenanceStatusLogListComponent } from './maintenance-status-log/maintenance-status-log-list.component';
import { MaintenanceStatusLogAddComponent } from './maintenance-status-log/maintenance-status-log-add.component';
import { MaintenanceStatusLogEditComponent } from './maintenance-status-log/maintenance-status-log-edit.component';
import { MaintenanceTaskLogListComponent } from './maintenance-task-log/maintenance-task-log-list.component';
import { MaintenanceTaskLogAddComponent } from './maintenance-task-log/maintenance-task-log-add.component';
import { MaintenanceTaskLogEditComponent } from './maintenance-task-log/maintenance-task-log-edit.component';
import { ServiceTicketListComponent } from './service-ticket/service-ticket-list.component';
import { ServiceTicketAddComponent } from './service-ticket/service-ticket-add.component';
import { ServiceTicketEditComponent } from './service-ticket/service-ticket-edit.component';
import { TicketCommentsListComponent } from './ticket-comments/ticket-comments-list.component';
import { TicketCommentsAddComponent } from './ticket-comments/ticket-comments-add.component';
import { TicketCommentsEditComponent } from './ticket-comments/ticket-comments-edit.component';
import { ErrorComponent } from './error/error.component';
import { AuthenticationService, SUPER_ADMIN, EMPLOYEE, MAINTENANCE, MAINTENANCE_PLANNER } from 'app/security/authentication.service';


export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: $localize`:@@home.index.headline:Welcome to your new app!`,
    data: {
      roles: [SUPER_ADMIN]
    }
  },
  {
    path: 'departments',
    component: DepartmentListComponent,
    title: $localize`:@@department.list.headline:Departments`,
    data: {
      roles: [SUPER_ADMIN]
    }
  },
  {
    path: 'departments/add',
    component: DepartmentAddComponent,
    title: $localize`:@@department.add.headline:Add Department`,
    data: {
      roles: [SUPER_ADMIN]
    }
  },
  {
    path: 'departments/edit/:departmentId',
    component: DepartmentEditComponent,
    title: $localize`:@@department.edit.headline:Edit Department`,
    data: {
      roles: [SUPER_ADMIN]
    }
  },
  {
    path: 'roles',
    component: RoleListComponent,
    title: $localize`:@@role.list.headline:Roles`,
    data: {
      roles: [SUPER_ADMIN]
    }
  },
  {
    path: 'roles/add',
    component: RoleAddComponent,
    title: $localize`:@@role.add.headline:Add Role`,
    data: {
      roles: [SUPER_ADMIN]
    }
  },
  {
    path: 'roles/edit/:roleId',
    component: RoleEditComponent,
    title: $localize`:@@role.edit.headline:Edit Role`,
    data: {
      roles: [SUPER_ADMIN]
    }
  },
  {
    path: 'employees',
    component: EmployeeListComponent,
    title: $localize`:@@employee.list.headline:Employees`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employees/add',
    component: EmployeeAddComponent,
    title: $localize`:@@employee.add.headline:Add Employee`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employees/edit/:employeeId',
    component: EmployeeEditComponent,
    title: $localize`:@@employee.edit.headline:Edit Employee`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeProfiles',
    component: EmployeeProfileListComponent,
    title: $localize`:@@employeeProfile.list.headline:Employee Profiles`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeProfiles/add',
    component: EmployeeProfileAddComponent,
    title: $localize`:@@employeeProfile.add.headline:Add Employee Profile`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeProfiles/edit/:profileId',
    component: EmployeeProfileEditComponent,
    title: $localize`:@@employeeProfile.edit.headline:Edit Employee Profile`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'machineLocations',
    component: MachineLocationListComponent,
    title: $localize`:@@machineLocation.list.headline:Machine Locations`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machineLocations/add',
    component: MachineLocationAddComponent,
    title: $localize`:@@machineLocation.add.headline:Add Machine Location`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machineLocations/edit/:locationId',
    component: MachineLocationEditComponent,
    title: $localize`:@@machineLocation.edit.headline:Edit Machine Location`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machineTypes',
    component: MachineTypeListComponent,
    title: $localize`:@@machineType.list.headline:Machine Types`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machineTypes/add',
    component: MachineTypeAddComponent,
    title: $localize`:@@machineType.add.headline:Add Machine Type`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machineTypes/edit/:typeId',
    component: MachineTypeEditComponent,
    title: $localize`:@@machineType.edit.headline:Edit Machine Type`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machineCategories',
    component: MachineCategoryListComponent,
    title: $localize`:@@machineCategory.list.headline:Machine Categories`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machineCategories/add',
    component: MachineCategoryAddComponent,
    title: $localize`:@@machineCategory.add.headline:Add Machine Category`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machineCategories/edit/:categoryId',
    component: MachineCategoryEditComponent,
    title: $localize`:@@machineCategory.edit.headline:Edit Machine Category`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'maintenanceTypes',
    component: MaintenanceTypeListComponent,
    title: $localize`:@@maintenanceType.list.headline:Maintenance Types`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'maintenanceTypes/add',
    component: MaintenanceTypeAddComponent,
    title: $localize`:@@maintenanceType.add.headline:Add Maintenance Type`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'maintenanceTypes/edit/:typeId',
    component: MaintenanceTypeEditComponent,
    title: $localize`:@@maintenanceType.edit.headline:Edit Maintenance Type`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'maintenances',
    component: MaintenanceListComponent,
    title: $localize`:@@maintenance.list.headline:Maintenances`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'maintenances/add',
    component: MaintenanceAddComponent,
    title: $localize`:@@maintenance.add.headline:Add Maintenance`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'maintenances/edit/:maintenanceId',
    component: MaintenanceEditComponent,
    title: $localize`:@@maintenance.edit.headline:Edit Maintenance`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'maintenanceTasks',
    component: MaintenanceTaskListComponent,
    title: $localize`:@@maintenanceTask.list.headline:Maintenance Tasks`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'maintenanceTasks/add',
    component: MaintenanceTaskAddComponent,
    title: $localize`:@@maintenanceTask.add.headline:Add Maintenance Task`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'maintenanceTasks/edit/:taskId',
    component: MaintenanceTaskEditComponent,
    title: $localize`:@@maintenanceTask.edit.headline:Edit Maintenance Task`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machines',
    component: MachineListComponent,
    title: $localize`:@@machine.list.headline:Machines`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machines/add',
    component: MachineAddComponent,
    title: $localize`:@@machine.add.headline:Add Machine`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'machines/edit/:machineId',
    component: MachineEditComponent,
    title: $localize`:@@machine.edit.headline:Edit Machine`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'employeeTimeOffs',
    component: EmployeeTimeOffListComponent,
    title: $localize`:@@employeeTimeOff.list.headline:Employee Time Offs`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeTimeOffs/add',
    component: EmployeeTimeOffAddComponent,
    title: $localize`:@@employeeTimeOff.add.headline:Add Employee Time Off`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeTimeOffs/edit/:id',
    component: EmployeeTimeOffEditComponent,
    title: $localize`:@@employeeTimeOff.edit.headline:Edit Employee Time Off`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeShifts',
    component: EmployeeShiftListComponent,
    title: $localize`:@@employeeShift.list.headline:Employee Shifts`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeShifts/add',
    component: EmployeeShiftAddComponent,
    title: $localize`:@@employeeShift.add.headline:Add Employee Shift`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeShifts/edit/:shiftId',
    component: EmployeeShiftEditComponent,
    title: $localize`:@@employeeShift.edit.headline:Edit Employee Shift`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeSchedules',
    component: EmployeeScheduleListComponent,
    title: $localize`:@@employeeSchedule.list.headline:Employee Schedules`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeSchedules/add',
    component: EmployeeScheduleAddComponent,
    title: $localize`:@@employeeSchedule.add.headline:Add Employee Schedule`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeSchedules/edit/:scheduleId',
    component: EmployeeScheduleEditComponent,
    title: $localize`:@@employeeSchedule.edit.headline:Edit Employee Schedule`,
    data: {
      roles: [SUPER_ADMIN, EMPLOYEE]
    }
  },
  {
    path: 'employeeRecurringSchedules',
    component: EmployeeRecurringScheduleListComponent,
    title: $localize`:@@employeeRecurringSchedule.list.headline:Employee Recurring Schedules`,
    data: {
      roles: [SUPER_ADMIN]
    }
  },
  {
    path: 'employeeRecurringSchedules/add',
    component: EmployeeRecurringScheduleAddComponent,
    title: $localize`:@@employeeRecurringSchedule.add.headline:Add Employee Recurring Schedule`,
    data: {
      roles: [SUPER_ADMIN]
    }
  },
  {
    path: 'employeeRecurringSchedules/edit/:id',
    component: EmployeeRecurringScheduleEditComponent,
    title: $localize`:@@employeeRecurringSchedule.edit.headline:Edit Employee Recurring Schedule`,
    data: {
      roles: [SUPER_ADMIN]
    }
  },
  {
    path: 'maintenanceSchedules',
    component: MaintenanceScheduleListComponent,
    title: $localize`:@@maintenanceSchedule.list.headline:Maintenance Schedules`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'maintenanceSchedules/add',
    component: MaintenanceScheduleAddComponent,
    title: $localize`:@@maintenanceSchedule.add.headline:Add Maintenance Schedule`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'maintenanceSchedules/edit/:scheduleId',
    component: MaintenanceScheduleEditComponent,
    title: $localize`:@@maintenanceSchedule.edit.headline:Edit Maintenance Schedule`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'maintenanceStatusLogs',
    component: MaintenanceStatusLogListComponent,
    title: $localize`:@@maintenanceStatusLog.list.headline:Maintenance Status Logs`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'maintenanceStatusLogs/add',
    component: MaintenanceStatusLogAddComponent,
    title: $localize`:@@maintenanceStatusLog.add.headline:Add Maintenance Status Log`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'maintenanceStatusLogs/edit/:id',
    component: MaintenanceStatusLogEditComponent,
    title: $localize`:@@maintenanceStatusLog.edit.headline:Edit Maintenance Status Log`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'maintenanceTaskLogs',
    component: MaintenanceTaskLogListComponent,
    title: $localize`:@@maintenanceTaskLog.list.headline:Maintenance Task Logs`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'maintenanceTaskLogs/add',
    component: MaintenanceTaskLogAddComponent,
    title: $localize`:@@maintenanceTaskLog.add.headline:Add Maintenance Task Log`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'maintenanceTaskLogs/edit/:taskId',
    component: MaintenanceTaskLogEditComponent,
    title: $localize`:@@maintenanceTaskLog.edit.headline:Edit Maintenance Task Log`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE]
    }
  },
  {
    path: 'serviceTickets',
    component: ServiceTicketListComponent,
    title: $localize`:@@serviceTicket.list.headline:Service Tickets`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'serviceTickets/add',
    component: ServiceTicketAddComponent,
    title: $localize`:@@serviceTicket.add.headline:Add Service Ticket`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'serviceTickets/edit/:ticketId',
    component: ServiceTicketEditComponent,
    title: $localize`:@@serviceTicket.edit.headline:Edit Service Ticket`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'ticketCommentss',
    component: TicketCommentsListComponent,
    title: $localize`:@@ticketComments.list.headline:Ticket Commentses`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'ticketCommentss/add',
    component: TicketCommentsAddComponent,
    title: $localize`:@@ticketComments.add.headline:Add Ticket Comments`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'ticketCommentss/edit/:commentId',
    component: TicketCommentsEditComponent,
    title: $localize`:@@ticketComments.edit.headline:Edit Ticket Comments`,
    data: {
      roles: [SUPER_ADMIN, MAINTENANCE, MAINTENANCE_PLANNER]
    }
  },
  {
    path: 'error',
    component: ErrorComponent,
    title: $localize`:@@error.headline:Error`
  },
  {
    path: '**',
    component: ErrorComponent,
    title: $localize`:@@notFound.headline:Page not found`
  }
];

// add authentication check to all routes
for (const route of routes) {
  route.canActivate = [(route: ActivatedRouteSnapshot) => inject(AuthenticationService).checkAccessAllowed(route)];
}
