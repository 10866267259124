import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { DepartmentDTO } from 'app/department/department.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';


@Injectable({
  providedIn: 'root',
})
export class DepartmentService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/departments';

  getAllDepartments(params?: Record<string,string>) {
    return this.http.get<PagedModel<DepartmentDTO>>(this.resourcePath, { params });
  }

  getDepartment(departmentId: number) {
    return this.http.get<DepartmentDTO>(this.resourcePath + '/' + departmentId);
  }

  createDepartment(departmentDTO: DepartmentDTO) {
    return this.http.post<number>(this.resourcePath, departmentDTO);
  }

  updateDepartment(departmentId: number, departmentDTO: DepartmentDTO) {
    return this.http.put<number>(this.resourcePath + '/' + departmentId, departmentDTO);
  }

  deleteDepartment(departmentId: number) {
    return this.http.delete(this.resourcePath + '/' + departmentId);
  }

}
