<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employeeSchedule.list.headline">Employee Schedules</h1>
    <div>
        <a routerLink="/employeeSchedules/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@employeeSchedule.list.createNew">Create new Employee Schedule</a>
    </div>
</div>
@if ((employeeSchedules && employeeSchedules.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@employeeSchedule.list.filter" placeholder="Filter by Schedule Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!employeeSchedules || employeeSchedules.page.totalElements === 0) {
<div i18n="@@employeeSchedule.list.empty">No Employee Schedules could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@employeeSchedule.scheduleId.label">Schedule Id</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeSchedule.scheduleDate.label">Schedule Date</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeSchedule.availabilityStatus.label">Availability Status</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeSchedule.employee.label">Employee</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeSchedule.shift.label">Shift</th>
                <th scope="col" class="text-left p-2" i18n="@@employeeSchedule.assignedBy.label">Assigned By</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (employeeSchedule of employeeSchedules.content; track employeeSchedule.scheduleId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ employeeSchedule.scheduleId }}</td>
                <td class="p-2">{{ employeeSchedule.scheduleDate }}</td>
                <td class="p-2">{{ employeeSchedule.availabilityStatus }}</td>
                <td class="p-2">{{ employeeSchedule.employeeName }}</td>
                <td class="p-2">{{ employeeSchedule.shiftName }}</td>
                <td class="p-2">{{ employeeSchedule.assignerName }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/employeeSchedules/edit', employeeSchedule.scheduleId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@employeeSchedule.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(employeeSchedule.scheduleId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@employeeSchedule.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="employeeSchedules.page" />
}
