import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { EmployeeTimeOffDTO } from 'app/employee-time-off/employee-time-off.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';
import { map } from 'rxjs';
import { transformRecordToMap } from 'app/common/utils';


@Injectable({
  providedIn: 'root',
})
export class EmployeeTimeOffService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/employeeTimeOffs';

  getAllEmployeeTimeOffs(params?: Record<string,string>) {
    return this.http.get<PagedModel<EmployeeTimeOffDTO>>(this.resourcePath, { params });
  }

  getEmployeeTimeOff(id: number) {
    return this.http.get<EmployeeTimeOffDTO>(this.resourcePath + '/' + id);
  }

  createEmployeeTimeOff(employeeTimeOffDTO: EmployeeTimeOffDTO) {
    return this.http.post<number>(this.resourcePath, employeeTimeOffDTO);
  }

  updateEmployeeTimeOff(id: number, employeeTimeOffDTO: EmployeeTimeOffDTO) {
    return this.http.put<number>(this.resourcePath + '/' + id, employeeTimeOffDTO);
  }

  deleteEmployeeTimeOff(id: number) {
    return this.http.delete(this.resourcePath + '/' + id);
  }

  getEmployeeValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/employeeValues')
        .pipe(map(transformRecordToMap));
  }

  getApprovedByValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/approvedByValues')
        .pipe(map(transformRecordToMap));
  }

}
