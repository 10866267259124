import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { MaintenanceScheduleService } from 'app/maintenance-schedule/maintenance-schedule.service';
import { MaintenanceScheduleDTO } from 'app/maintenance-schedule/maintenance-schedule.model';
import { SearchFilterComponent } from 'app/common/list-helper/search-filter.component';
import { SortingComponent } from 'app/common/list-helper/sorting.component';
import { getListParams } from 'app/common/utils';
import { PagedModel, PaginationComponent } from 'app/common/list-helper/pagination.component';


@Component({
  selector: 'app-maintenance-schedule-list',
  standalone: true,
  imports: [CommonModule, SearchFilterComponent ,SortingComponent, PaginationComponent, RouterLink],
  templateUrl: './maintenance-schedule-list.component.html'})
export class MaintenanceScheduleListComponent implements OnInit, OnDestroy {

  maintenanceScheduleService = inject(MaintenanceScheduleService);
  errorHandler = inject(ErrorHandler);
  route = inject(ActivatedRoute);
  router = inject(Router);
  maintenanceSchedules?: PagedModel<MaintenanceScheduleDTO>;
  navigationSubscription?: Subscription;

  sortOptions = {
    'scheduleId,ASC': $localize`:@@maintenanceSchedule.list.sort.scheduleId,ASC:Sort by Schedule Id (Ascending)`, 
    'assignmentStatus,ASC': $localize`:@@maintenanceSchedule.list.sort.assignmentStatus,ASC:Sort by Assignment Status (Ascending)`, 
    'scheduledDate,ASC': $localize`:@@maintenanceSchedule.list.sort.scheduledDate,ASC:Sort by Scheduled Date (Ascending)`
  }

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      confirm: $localize`:@@delete.confirm:Do you really want to delete this element? This cannot be undone.`,
      deleted: $localize`:@@maintenanceSchedule.delete.success:Maintenance Schedule was removed successfully.`    };
    return messages[key];
  }

  ngOnInit() {
    this.loadData();
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadData();
      }
    });
  }

  ngOnDestroy() {
    this.navigationSubscription!.unsubscribe();
  }
  
  loadData() {
    this.maintenanceScheduleService.getAllMaintenanceSchedules(getListParams(this.route))
        .subscribe({
          next: (data) => this.maintenanceSchedules = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  confirmDelete(scheduleId: number) {
    if (confirm(this.getMessage('confirm'))) {
      this.maintenanceScheduleService.deleteMaintenanceSchedule(scheduleId)
          .subscribe({
            next: () => this.router.navigate(['/maintenanceSchedules'], {
              state: {
                msgInfo: this.getMessage('deleted')
              }
            }),
            error: (error) => this.errorHandler.handleServerError(error.error)
          });
    }
  }

}
