import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { ServiceTicketService } from 'app/service-ticket/service-ticket.service';
import { ServiceTicketDTO } from 'app/service-ticket/service-ticket.model';
import { SearchFilterComponent } from 'app/common/list-helper/search-filter.component';
import { SortingComponent } from 'app/common/list-helper/sorting.component';
import { getListParams } from 'app/common/utils';
import { PagedModel, PaginationComponent } from 'app/common/list-helper/pagination.component';


@Component({
  selector: 'app-service-ticket-list',
  standalone: true,
  imports: [CommonModule, SearchFilterComponent ,SortingComponent, PaginationComponent, RouterLink],
  templateUrl: './service-ticket-list.component.html'})
export class ServiceTicketListComponent implements OnInit, OnDestroy {

  serviceTicketService = inject(ServiceTicketService);
  errorHandler = inject(ErrorHandler);
  route = inject(ActivatedRoute);
  router = inject(Router);
  serviceTickets?: PagedModel<ServiceTicketDTO>;
  navigationSubscription?: Subscription;

  sortOptions = {
    'ticketId,ASC': $localize`:@@serviceTicket.list.sort.ticketId,ASC:Sort by Ticket Id (Ascending)`, 
    'issueDescription,ASC': $localize`:@@serviceTicket.list.sort.issueDescription,ASC:Sort by Issue Description (Ascending)`, 
    'ticketStatus,ASC': $localize`:@@serviceTicket.list.sort.ticketStatus,ASC:Sort by Ticket Status (Ascending)`
  }

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      confirm: $localize`:@@delete.confirm:Do you really want to delete this element? This cannot be undone.`,
      deleted: $localize`:@@serviceTicket.delete.success:Service Ticket was removed successfully.`,
      'serviceTicket.ticketComments.ticket.referenced': $localize`:@@serviceTicket.ticketComments.ticket.referenced:This entity is still referenced by Ticket Comments ${details?.id} via field Ticket.`
    };
    return messages[key];
  }

  ngOnInit() {
    this.loadData();
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadData();
      }
    });
  }

  ngOnDestroy() {
    this.navigationSubscription!.unsubscribe();
  }
  
  loadData() {
    this.serviceTicketService.getAllServiceTickets(getListParams(this.route))
        .subscribe({
          next: (data) => this.serviceTickets = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  confirmDelete(ticketId: number) {
    if (confirm(this.getMessage('confirm'))) {
      this.serviceTicketService.deleteServiceTicket(ticketId)
          .subscribe({
            next: () => this.router.navigate(['/serviceTickets'], {
              state: {
                msgInfo: this.getMessage('deleted')
              }
            }),
            error: (error) => {
              if (error.error?.code === 'REFERENCED') {
                const messageParts = error.error.message.split(',');
                this.router.navigate(['/serviceTickets'], {
                  state: {
                    msgError: this.getMessage(messageParts[0], { id: messageParts[1] })
                  }
                });
                return;
              }
              this.errorHandler.handleServerError(error.error)
            }
          });
    }
  }

}
