<header class="bg-gray-50">
    <div class="container px-4 md:px-6">
        <nav class="flex flex-wrap items-center justify-between py-2">
            <a routerLink="/" class="flex py-1.5 mr-4">
                <img ngSrc="logo.png" i18n-alt="@@app.title" alt="Juicerobo" width="30" height="30" class="inline-block">
                <span class="text-xl pl-3" i18n="@@app.title">Juicerobo</span>
            </a>
            <button type="button" class="js-dropdown md:hidden border rounded" data-dropdown-keepopen="true"
                    i18n-aria-label="@@navigation.toggle" aria-label="Toggle navigation" aria-controls="navbarToggle" aria-expanded="false">
                <div class="space-y-1.5 my-2.5 mx-4">
                    <div class="w-6 h-0.5 bg-gray-500"></div>
                    <div class="w-6 h-0.5 bg-gray-500"></div>
                    <div class="w-6 h-0.5 bg-gray-500"></div>
                </div>
            </button>
            <div class="hidden md:block flex grow md:grow-0 justify-end basis-full md:basis-auto pt-3 md:pt-1 pb-1" id="navbarToggle">
                <ul class="flex">
                    <li>
                        <a routerLink="/" class="block text-gray-500 p-2" i18n="@@navigation.home">Home</a>
                    </li>
                    <li class="relative">
                        <a class="js-dropdown block text-gray-500 p-2" href="#" id="navbarEntitiesLink" role="button"
                                aria-expanded="false">
                            <span i18n="@@navigation.entities">Entities</span>
                            <span class="text-[9px] align-[3px] pl-0.5">&#9660;</span>
                        </a>
                        <ul class="hidden block absolute right-0 bg-white border border-gray-300 rounded min-w-[10rem] py-2" aria-labelledby="navbarEntitiesLink">
                            <li><a routerLink="/departments" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@department.list.headline">Departments</a></li>
                            <li><a routerLink="/roles" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@role.list.headline">Roles</a></li>
                            <li><a routerLink="/employees" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@employee.list.headline">Employees</a></li>
                            <li><a routerLink="/employeeProfiles" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@employeeProfile.list.headline">Employee Profiles</a></li>
                            <li><a routerLink="/machineLocations" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@machineLocation.list.headline">Machine Locations</a></li>
                            <li><a routerLink="/machineTypes" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@machineType.list.headline">Machine Types</a></li>
                            <li><a routerLink="/machineCategories" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@machineCategory.list.headline">Machine Categories</a></li>
                            <li><a routerLink="/maintenanceTypes" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@maintenanceType.list.headline">Maintenance Types</a></li>
                            <li><a routerLink="/maintenances" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@maintenance.list.headline">Maintenances</a></li>
                            <li><a routerLink="/maintenanceTasks" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@maintenanceTask.list.headline">Maintenance Tasks</a></li>
                            <li><a routerLink="/machines" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@machine.list.headline">Machines</a></li>
                            <li><a routerLink="/employeeTimeOffs" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@employeeTimeOff.list.headline">Employee Time Offs</a></li>
                            <li><a routerLink="/employeeShifts" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@employeeShift.list.headline">Employee Shifts</a></li>
                            <li><a routerLink="/employeeSchedules" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@employeeSchedule.list.headline">Employee Schedules</a></li>
                            <li><a routerLink="/employeeRecurringSchedules" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@employeeRecurringSchedule.list.headline">Employee Recurring Schedules</a></li>
                            <li><a routerLink="/maintenanceSchedules" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@maintenanceSchedule.list.headline">Maintenance Schedules</a></li>
                            <li><a routerLink="/maintenanceStatusLogs" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@maintenanceStatusLog.list.headline">Maintenance Status Logs</a></li>
                            <li><a routerLink="/maintenanceTaskLogs" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@maintenanceTaskLog.list.headline">Maintenance Task Logs</a></li>
                            <li><a routerLink="/serviceTickets" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@serviceTicket.list.headline">Service Tickets</a></li>
                            <li><a routerLink="/ticketCommentss" class="inline-block w-full hover:bg-gray-200 px-4 py-1" i18n="@@ticketComments.list.headline">Ticket Commentses</a></li>
                        </ul>
                    </li>
                    <li>
                        @if (authenticationService.isLoggedIn()) {
                        <button type="button" (click)="authenticationService.logout()" class="block text-gray-500 p-2" i18n="@@navigation.logout">Logout</button>
                        } @else {
                        <button type="button" (click)="authenticationService.login()" class="block text-gray-500 p-2" i18n="@@navigation.login">Login</button>
                        }
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>
