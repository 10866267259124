<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@machineLocation.edit.headline">Edit Machine Location</h1>
    <div>
        <a routerLink="/machineLocations" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@machineLocation.edit.back">Back to list</a>
    </div>
</div>
<form [formGroup]="editForm" (ngSubmit)="handleSubmit()">
    <input type="submit" i18n-value="@@machineLocation.edit.headline" value="Edit Machine Location" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6 mb-5" />
    <app-input-row [group]="editForm" field="locationId" rowType="number" i18n-label="@@machineLocation.locationId.label" label="Location Id" />
    <app-input-row [group]="editForm" field="locationName" i18n-label="@@machineLocation.locationName.label" label="Location Name" />
    <app-input-row [group]="editForm" field="locationType" rowType="radio" [options]="{'VENUE': 'VENUE', 'SITE': 'SITE', 'OTHER': 'OTHER'}" i18n-label="@@machineLocation.locationType.label" label="Location Type" />
    <app-input-row [group]="editForm" field="locationDescription" rowType="textarea" i18n-label="@@machineLocation.locationDescription.label" label="Location Description" />
    <app-input-row [group]="editForm" field="officialName" i18n-label="@@machineLocation.officialName.label" label="Official Name" />
    <app-input-row [group]="editForm" field="phone" i18n-label="@@machineLocation.phone.label" label="Phone" />
    <app-input-row [group]="editForm" field="email" i18n-label="@@machineLocation.email.label" label="Email" />
    <app-input-row [group]="editForm" field="isActive" rowType="checkbox" i18n-label="@@machineLocation.isActive.label" label="Is Active" />
    <app-input-row [group]="editForm" field="openingHours" i18n-label="@@machineLocation.openingHours.label" label="Opening Hours" />
    <app-input-row [group]="editForm" field="addressLine1" i18n-label="@@machineLocation.addressLine1.label" label="Address Line1" />
    <app-input-row [group]="editForm" field="addressLine2" i18n-label="@@machineLocation.addressLine2.label" label="Address Line2" />
    <app-input-row [group]="editForm" field="landmark" i18n-label="@@machineLocation.landmark.label" label="Landmark" />
    <app-input-row [group]="editForm" field="notes" rowType="textarea" i18n-label="@@machineLocation.notes.label" label="Notes" />
    <app-input-row [group]="editForm" field="postCode" i18n-label="@@machineLocation.postCode.label" label="Post Code" />
    <app-input-row [group]="editForm" field="city" i18n-label="@@machineLocation.city.label" label="City" />
    <app-input-row [group]="editForm" field="state" i18n-label="@@machineLocation.state.label" label="State" />
    <app-input-row [group]="editForm" field="country" i18n-label="@@machineLocation.country.label" label="Country" />
    <app-input-row [group]="editForm" field="latitude" i18n-label="@@machineLocation.latitude.label" label="Latitude" />
    <app-input-row [group]="editForm" field="longitude" i18n-label="@@machineLocation.longitude.label" label="Longitude" />
    <app-input-row [group]="editForm" field="plusCode" i18n-label="@@machineLocation.plusCode.label" label="Plus Code" />
    <input type="submit" i18n-value="@@machineLocation.edit.headline" value="Edit Machine Location" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
