<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@maintenanceStatusLog.add.headline">Add Maintenance Status Log</h1>
    <div>
        <a routerLink="/maintenanceStatusLogs" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@maintenanceStatusLog.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="status" rowType="select" [options]="{'SCHEDULED': 'SCHEDULED', 'IN_PROGRESS': 'IN_PROGRESS', 'COMPLETED': 'COMPLETED', 'CANCELED': 'CANCELED', 'POSTPONED': 'POSTPONED', 'OVERDUE': 'OVERDUE'}" i18n-label="@@maintenanceStatusLog.status.label" label="Status" />
    <app-input-row [group]="addForm" field="maintenance" rowType="select" [options]="maintenanceValues" i18n-label="@@maintenanceStatusLog.maintenance.label" label="Maintenance" />
    <app-input-row [group]="addForm" field="assignedEmployeeEmail" rowType="select" [options]="assignedEmployeeEmailValues" i18n-label="@@maintenanceStatusLog.assignedEmployeeEmail.label" label="Assigned Employee Email" />
    <app-input-row [group]="addForm" field="machine" rowType="select" [options]="machineValues" i18n-label="@@maintenanceStatusLog.machine.label" label="Machine" />
    <input type="submit" i18n-value="@@maintenanceStatusLog.add.headline" value="Add Maintenance Status Log" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
