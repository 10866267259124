import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MachineLocationDTO } from 'app/machine-location/machine-location.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';


@Injectable({
  providedIn: 'root',
})
export class MachineLocationService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/machineLocations';

  getAllMachineLocations(params?: Record<string,string>) {
    return this.http.get<PagedModel<MachineLocationDTO>>(this.resourcePath, { params });
  }

  getMachineLocation(locationId: number) {
    return this.http.get<MachineLocationDTO>(this.resourcePath + '/' + locationId);
  }

  createMachineLocation(machineLocationDTO: MachineLocationDTO) {
    return this.http.post<number>(this.resourcePath, machineLocationDTO);
  }

  updateMachineLocation(locationId: number, machineLocationDTO: MachineLocationDTO) {
    return this.http.put<number>(this.resourcePath + '/' + locationId, machineLocationDTO);
  }

  deleteMachineLocation(locationId: number) {
    return this.http.delete(this.resourcePath + '/' + locationId);
  }

}
