<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employeeRecurringSchedule.add.headline">Add Employee Recurring Schedule</h1>
    <div>
        <a routerLink="/employeeRecurringSchedules" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@employeeRecurringSchedule.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="startDate" datepicker="datepicker" i18n-label="@@employeeRecurringSchedule.startDate.label" label="Start Date" />
    <app-input-row [group]="addForm" field="endDate" datepicker="datepicker" i18n-label="@@employeeRecurringSchedule.endDate.label" label="End Date" />
    <app-input-row [group]="addForm" field="employee" rowType="select" [options]="employeeValues" i18n-label="@@employeeRecurringSchedule.employee.label" label="Employee" />
    <app-input-row [group]="addForm" field="shift" rowType="select" [options]="shiftValues" i18n-label="@@employeeRecurringSchedule.shift.label" label="Shift" />
    <input type="submit" i18n-value="@@employeeRecurringSchedule.add.headline" value="Add Employee Recurring Schedule" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
