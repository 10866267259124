<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@employee.add.headline">Add Employee</h1>
    <div>
        <a routerLink="/employees" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-4 rounded px-5 py-2" i18n="@@employee.add.back">Back to list</a>
    </div>
</div>
<form [formGroup]="addForm" (ngSubmit)="handleSubmit()">
    <app-input-row [group]="addForm" field="firstName" i18n-label="@@employee.firstName.label" label="First Name" />
    <app-input-row [group]="addForm" field="lastName" i18n-label="@@employee.lastName.label" label="Last Name" />
    <app-input-row [group]="addForm" field="email" i18n-label="@@employee.email.label" label="Email" />
    <app-input-row [group]="addForm" field="phoneNumber" i18n-label="@@employee.phoneNumber.label" label="Phone Number" />
    <app-input-row [group]="addForm" field="password" rowType="textarea" i18n-label="@@employee.password.label" label="Password" />
    <app-input-row [group]="addForm" field="externalId" i18n-label="@@employee.externalId.label" label="External Id" />
    <app-input-row [group]="addForm" field="isActive" rowType="checkbox" i18n-label="@@employee.isActive.label" label="Is Active" />
    <app-input-row [group]="addForm" field="roles" rowType="multiselect" [options]="rolesValues" i18n-label="@@employee.roles.label" label="Roles" />
    <input type="submit" i18n-value="@@employee.add.headline" value="Add Employee" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2 mt-6" />
</form>
