export class ServiceTicketDTO {

  constructor(data:Partial<ServiceTicketDTO>) {
    Object.assign(this, data);
  }

  ticketId?: number|null;
  issueDescription?: string|null;
  ticketStatus?: string|null;
  priority?: string|null;
  taskPhotoProofUrl?: string|null;
  assignedDate?: string|null;
  resolvedDate?: string|null;
  machine?: number|null;
  machineName?: string|null;
  reportedBy?: number|null;
  reporterName?: string|null;
  assignedTo?: number|null;
  assigneeName?: string|null;
  maintenance?: number|null;
  maintenanceName?: string|null;

}
