export class EmployeeProfileDTO {

  constructor(data:Partial<EmployeeProfileDTO>) {
    Object.assign(this, data);
  }

  profileId?: number|null;
  jobTitle?: string|null;
  hireDate?: string|null;
  gender?: string|null;
  birthdate?: string|null;
  driverLicense?: string|null;
  vehicleDetails?: string|null;
  addressLine1?: string|null;
  addressLine2?: string|null;
  landmark?: string|null;
  notes?: string|null;
  postCode?: string|null;
  city?: string|null;
  state?: string|null;
  country?: string|null;
  latitude?: string|null;
  longitude?: string|null;
  plusCode?: string|null;
  profilePicUrl?: string|null;
  employee?: number|null;
  department?: number|null;

}
