import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { EmployeeProfileDTO } from 'app/employee-profile/employee-profile.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';
import { map } from 'rxjs';
import { transformRecordToMap } from 'app/common/utils';


@Injectable({
  providedIn: 'root',
})
export class EmployeeProfileService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/employeeProfiles';

  getAllEmployeeProfiles(params?: Record<string,string>) {
    return this.http.get<PagedModel<EmployeeProfileDTO>>(this.resourcePath, { params });
  }

  getEmployeeProfile(profileId: number) {
    return this.http.get<EmployeeProfileDTO>(this.resourcePath + '/' + profileId);
  }

  createEmployeeProfile(employeeProfileDTO: EmployeeProfileDTO) {
    return this.http.post<number>(this.resourcePath, employeeProfileDTO);
  }

  updateEmployeeProfile(profileId: number, employeeProfileDTO: EmployeeProfileDTO) {
    return this.http.put<number>(this.resourcePath + '/' + profileId, employeeProfileDTO);
  }

  deleteEmployeeProfile(profileId: number) {
    return this.http.delete(this.resourcePath + '/' + profileId);
  }

  getEmployeeValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/employeeValues')
        .pipe(map(transformRecordToMap));
  }

  getDepartmentValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/departmentValues')
        .pipe(map(transformRecordToMap));
  }

}
