<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@department.list.headline">Departments</h1>
    <div>
        <a routerLink="/departments/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@department.list.createNew">Create new Department</a>
    </div>
</div>
@if ((departments && departments.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@department.list.filter" placeholder="Filter by Department Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!departments || departments.page.totalElements === 0) {
<div i18n="@@department.list.empty">No Departments could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@department.departmentId.label">Department Id</th>
                <th scope="col" class="text-left p-2" i18n="@@department.departmentName.label">Department Name</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (department of departments.content; track department.departmentId) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ department.departmentId }}</td>
                <td class="p-2">{{ department.departmentName }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/departments/edit', department.departmentId]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@department.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(department.departmentId!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@department.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="departments.page" />
}
