<div class="flex flex-wrap mb-6">
    <h1 class="grow text-3xl md:text-4xl font-medium mb-2" i18n="@@maintenanceStatusLog.list.headline">Maintenance Status Logs</h1>
    <div>
        <a routerLink="/maintenanceStatusLogs/add" class="inline-block text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300  focus:ring-4 rounded px-5 py-2" i18n="@@maintenanceStatusLog.list.createNew">Create new Maintenance Status Log</a>
    </div>
</div>
@if ((maintenanceStatusLogs && maintenanceStatusLogs.page.totalElements !== 0) || route.snapshot.queryParamMap.get('filter')) {
<div class="flex flex-wrap justify-between">
    <app-search-filter i18n-placeholder="@@maintenanceStatusLog.list.filter" placeholder="Filter by Id" class="w-full md:w-1/2 xl:w-1/3 md:pr-2" />
    <app-sorting [sortOptions]="sortOptions" class="w-full md:w-1/2 xl:w-1/3" />
</div>
}
@if (!maintenanceStatusLogs || maintenanceStatusLogs.page.totalElements === 0) {
<div i18n="@@maintenanceStatusLog.list.empty">No Maintenance Status Logs could be found.</div>
} @else {
<div class="overflow-x-auto">
    <table class="w-full">
        <thead>
            <tr>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceStatusLog.id.label">Id</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceStatusLog.status.label">Status</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceStatusLog.maintenance.label">Maintenance</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceStatusLog.assignedEmployeeEmail.label">Assigned Employee Email</th>
                <th scope="col" class="text-left p-2" i18n="@@maintenanceStatusLog.machine.label">Machine</th>
                <th><!-- --></th>
            </tr>
        </thead>
        <tbody class="border-t-2 border-black">
            @for (maintenanceStatusLog of maintenanceStatusLogs.content; track maintenanceStatusLog.id) {
            <tr class="odd:bg-gray-100">
                <td class="p-2">{{ maintenanceStatusLog.id }}</td>
                <td class="p-2">{{ maintenanceStatusLog.status }}</td>
                <td class="p-2">{{ maintenanceStatusLog.maintenanceName }}</td>
                <td class="p-2">{{ maintenanceStatusLog.assigneeName }}</td>
                <td class="p-2">{{ maintenanceStatusLog.machineName }}</td>
                <td class="p-2">
                    <div class="float-right whitespace-nowrap">
                        <a [routerLink]="['/maintenanceStatusLogs/edit', maintenanceStatusLog.id]" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@maintenanceStatusLog.list.edit">Edit</a>
                        <button type="button" (click)="confirmDelete(maintenanceStatusLog.id!)" class="inline-block text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-200 focus:ring-3 rounded px-2.5 py-1.5 text-sm" i18n="@@maintenanceStatusLog.list.delete">Delete</button>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<app-pagination [page]="maintenanceStatusLogs.page" />
}
