import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorHandler } from 'app/common/error-handler.injectable';
import { EmployeeScheduleService } from 'app/employee-schedule/employee-schedule.service';
import { EmployeeScheduleDTO } from 'app/employee-schedule/employee-schedule.model';
import { SearchFilterComponent } from 'app/common/list-helper/search-filter.component';
import { SortingComponent } from 'app/common/list-helper/sorting.component';
import { getListParams } from 'app/common/utils';
import { PagedModel, PaginationComponent } from 'app/common/list-helper/pagination.component';


@Component({
  selector: 'app-employee-schedule-list',
  standalone: true,
  imports: [CommonModule, SearchFilterComponent ,SortingComponent, PaginationComponent, RouterLink],
  templateUrl: './employee-schedule-list.component.html'})
export class EmployeeScheduleListComponent implements OnInit, OnDestroy {

  employeeScheduleService = inject(EmployeeScheduleService);
  errorHandler = inject(ErrorHandler);
  route = inject(ActivatedRoute);
  router = inject(Router);
  employeeSchedules?: PagedModel<EmployeeScheduleDTO>;
  navigationSubscription?: Subscription;

  sortOptions = {
    'scheduleId,ASC': $localize`:@@employeeSchedule.list.sort.scheduleId,ASC:Sort by Schedule Id (Ascending)`, 
    'scheduleDate,ASC': $localize`:@@employeeSchedule.list.sort.scheduleDate,ASC:Sort by Schedule Date (Ascending)`, 
    'availabilityStatus,ASC': $localize`:@@employeeSchedule.list.sort.availabilityStatus,ASC:Sort by Availability Status (Ascending)`
  }

  getMessage(key: string, details?: any) {
    const messages: Record<string, string> = {
      confirm: $localize`:@@delete.confirm:Do you really want to delete this element? This cannot be undone.`,
      deleted: $localize`:@@employeeSchedule.delete.success:Employee Schedule was removed successfully.`    };
    return messages[key];
  }

  ngOnInit() {
    this.loadData();
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadData();
      }
    });
  }

  ngOnDestroy() {
    this.navigationSubscription!.unsubscribe();
  }
  
  loadData() {
    this.employeeScheduleService.getAllEmployeeSchedules(getListParams(this.route))
        .subscribe({
          next: (data) => this.employeeSchedules = data,
          error: (error) => this.errorHandler.handleServerError(error.error)
        });
  }

  confirmDelete(scheduleId: number) {
    if (confirm(this.getMessage('confirm'))) {
      this.employeeScheduleService.deleteEmployeeSchedule(scheduleId)
          .subscribe({
            next: () => this.router.navigate(['/employeeSchedules'], {
              state: {
                msgInfo: this.getMessage('deleted')
              }
            }),
            error: (error) => this.errorHandler.handleServerError(error.error)
          });
    }
  }

}
