import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { EmployeeShiftDTO } from 'app/employee-shift/employee-shift.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';


@Injectable({
  providedIn: 'root',
})
export class EmployeeShiftService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/employeeShifts';

  getAllEmployeeShifts(params?: Record<string,string>) {
    return this.http.get<PagedModel<EmployeeShiftDTO>>(this.resourcePath, { params });
  }

  getEmployeeShift(shiftId: number) {
    return this.http.get<EmployeeShiftDTO>(this.resourcePath + '/' + shiftId);
  }

  createEmployeeShift(employeeShiftDTO: EmployeeShiftDTO) {
    return this.http.post<number>(this.resourcePath, employeeShiftDTO);
  }

  updateEmployeeShift(shiftId: number, employeeShiftDTO: EmployeeShiftDTO) {
    return this.http.put<number>(this.resourcePath + '/' + shiftId, employeeShiftDTO);
  }

  deleteEmployeeShift(shiftId: number) {
    return this.http.delete(this.resourcePath + '/' + shiftId);
  }

}
