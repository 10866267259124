import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { EmployeeRecurringScheduleDTO } from 'app/employee-recurring-schedule/employee-recurring-schedule.model';
import { PagedModel } from 'app/common/list-helper/pagination.component';
import { map } from 'rxjs';
import { transformRecordToMap } from 'app/common/utils';


@Injectable({
  providedIn: 'root',
})
export class EmployeeRecurringScheduleService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/employeeRecurringSchedules';

  getAllEmployeeRecurringSchedules(params?: Record<string,string>) {
    return this.http.get<PagedModel<EmployeeRecurringScheduleDTO>>(this.resourcePath, { params });
  }

  getEmployeeRecurringSchedule(id: number) {
    return this.http.get<EmployeeRecurringScheduleDTO>(this.resourcePath + '/' + id);
  }

  createEmployeeRecurringSchedule(employeeRecurringScheduleDTO: EmployeeRecurringScheduleDTO) {
    return this.http.post<number>(this.resourcePath, employeeRecurringScheduleDTO);
  }

  updateEmployeeRecurringSchedule(id: number, employeeRecurringScheduleDTO: EmployeeRecurringScheduleDTO) {
    return this.http.put<number>(this.resourcePath + '/' + id, employeeRecurringScheduleDTO);
  }

  deleteEmployeeRecurringSchedule(id: number) {
    return this.http.delete(this.resourcePath + '/' + id);
  }

  getEmployeeValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/employeeValues')
        .pipe(map(transformRecordToMap));
  }

  getShiftValues() {
    return this.http.get<Record<string,string>>(this.resourcePath + '/shiftValues')
        .pipe(map(transformRecordToMap));
  }

}
